import React, { useContext, useEffect, useState } from 'react';
import {
    Stack,
    TextField,
    MenuItem,
    IconButton, Button,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { actionCreators as gameActionCreators } from '../../../store/modules/Game';
import { actionCreators as ruleActionCreators } from '../../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../../store/modules/Ranking';
import { actionCreators as leaderboardActionCreators } from '../../../store/modules/Leaderboard';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import helper from '../../../utils/helper';
import EditorBase from '../EditorBase';
import {
    ConvertToOptions,
    NotificationHours,
    SettingType,
} from '../../../utils/enum';
import * as Setting from '../../../store/modules/Setting';
import { getProgressRatio, getRewardsAndPenalties } from '../utils/messages';
import JourneyContext from '../JourneyContext';
import ProgressConditionsEditor from './ProgressConditionsEditor';
import { TextFieldDeferred } from './TextFieldDeferred';

const MessageEditor = (props) => {
    const { onUpdate, onDelete, type, enableMessageCustomization, requestGetRule, requestGetRanking, ruleDetails, rankingDetails, unionRuleId } = props;

    const value = props.value || {
        title: null,
        body: null,
        hourFrom: null,
        hourTo: null,
        conditions: []
    };

    const { title, body, hourFrom, hourTo, conditions } = value;

    const { aimessage } = props;

    const [localTitle, setLocalTitle] = useState(value.title);
    const [localBody, setLocalBody] = useState(value.body);

    useEffect(() => {
        setLocalTitle(value.title);
        setLocalBody(value.body);
    }, [value.title, value.body]);

    const { activeProfileGame, allRules, allRankings, isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    useEffect(() => {
        if (aimessage) {
            if (
                aimessage.type === type &&
                aimessage.gameId == activeProfileGame.id &&
                (aimessage.unionRuleId === unionRuleId || unionRuleId === undefined)
            ) {
                if (aimessage.title !== localTitle || aimessage.body !== localBody) {
                    const { clearAiMessage } = props;
                    setLocalTitle(aimessage.title);
                    setLocalBody(aimessage.body);
                    onUpdate({
                        ...value,
                        title: aimessage.title,
                        body: aimessage.body,
                        unionRuleId: aimessage.unionRuleId
                    });
                    clearAiMessage();
                }
            }
        }
    }, [aimessage, type, activeProfileGame.id, unionRuleId]);

    useEffect(() => {
        if (isGameMode) {
            const gameRuleIds = new Set(activeProfileGame.ruleUnions.flatMap(ru => ru.rules).map(r => r.id).filter(id => id !== null));
            const knownRuleIds = new Set(Object.values(ruleDetails).map(r => r.id));
            const unknownRuleIds = [...gameRuleIds].filter(id => !knownRuleIds.has(id));
            unknownRuleIds.forEach(id => requestGetRule(id));
        }
        else if (isLeaderboardMode) {
            const gameRankingIds = new Set(activeProfileGame.ruleUnions.flatMap(ru => ru.rules).map(r => r.id).filter(id => id !== null));
            const knownRankingIds = new Set(Object.values(rankingDetails).map(r => r.id));
            const unknownRankingIds = [...gameRankingIds].filter(id => !knownRankingIds.has(id));
            unknownRankingIds.forEach(id => requestGetRanking(id));
        }
    }, [activeProfileGame.ruleUnions]);

    const summary = () => {
        if (!title) {
            return "None";
        }

        if (title.length <= 50) {
            return title;
        }
        else {
            return title.substring(0, 50) + "...";
        }
    };

    const handleTitleChange = (newValue) => {
        setLocalTitle(newValue);
        onUpdate({ ...value, title: newValue });
    };

    const handleBodyChange = (newValue) => {
        setLocalBody(newValue);
        onUpdate({ ...value, body: newValue });
    };

    const handleHourFromChange = (event) => {
        onUpdate({ ...value, hourFrom: event.target.value });
    };

    const handleHourToChange = (event) => {
        onUpdate({ ...value, hourTo: event.target.value });
    };

    const handleClearHourFromClick = () => {
        onUpdate({ ...value, hourFrom: null });
    };

    const handleClearHourToClick = () => {
        onUpdate({ ...value, hourTo: null });
    };

    const handleConditionsChange = (conditions) => {
        onUpdate({ ...value, conditions: conditions });
    };

    const deleteMessage = () => {
        onDelete();
    }

    const generateMessageText = () => {
        const { generateAiMessage } = props;

        let { rewards, penalties } = getRewardsAndPenalties(activeProfileGame.rewardUnions, unionRuleId);
        let progressRatio = getProgressRatio(conditions, activeProfileGame.ruleUnions, ruleDetails);

        generateAiMessage(type, activeProfileGame.name, activeProfileGame.description, allRules.map(r => r.name), rewards, penalties, progressRatio, unionRuleId, activeProfileGame.id);
    }

    return (
        <EditorBase title="Message" summary={summary()}>
            <Stack spacing={2}>
                <TextFieldDeferred
                    label="Title"
                    fullWidth
                    value={localTitle || ""}
                    onChangeDeferred={handleTitleChange}
                    required
                />
                <TextFieldDeferred
                    label="Body"
                    fullWidth
                    multiline
                    value={localBody || ""}
                    onChangeDeferred={handleBodyChange}
                    required
                />

                <Stack direction="row" spacing={1}>
                    <HourSelect
                        value={hourFrom}
                        onChange={handleHourFromChange}
                        label="Timing From"
                        onClick={handleClearHourFromClick}
                    />

                    <HourSelect
                        value={hourTo}
                        onChange={handleHourToChange}
                        label="Timing To"
                        onClick={handleClearHourToClick}
                    />
                </Stack>

                {enableMessageCustomization && (
                    <Button variant="outlined" onClick={generateMessageText}>Propose Message Text</Button>
                )}
                <Button variant="outlined" color="warning" onClick={deleteMessage}>Delete Message</Button>

                {(type === 'progress' || type === 'milestoneProgress') && (
                    <ProgressConditionsEditor value={conditions} onChange={handleConditionsChange} />
                )}
            </Stack>
        </EditorBase>
    );
};

MessageEditor.propTypes = {
    clearAiMessage: PropTypes.func.isRequired,
    generateAiMessage: PropTypes.func.isRequired,
    requestGetRule: PropTypes.func.isRequired,
    requestGetRanking: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => ({
        aimessage: state.game.aimessage,
        enableMessageCustomization: Setting.selectors.getSettingAsBoolean(state, SettingType.EnableMessageCustomization),
        ruleDetails: state.rule.details,
        rankingDetails: state.ranking.details
    }),
    (dispatch, ownProps) => bindActionCreators(
        {
            ...helper.gameResolver(ownProps, gameActionCreators, leaderboardActionCreators),
            requestGetRule: ruleActionCreators.requestGet,
            requestGetRanking: rankingActionCreators.requestGet
        },
        dispatch,
    ),
)(MessageEditor);

const HourSelect = ({
    value,
    onChange,
    label,
    onClick,
}) => (
    <TextField
        value={value || ""}
        onChange={onChange}
        select
        label={label}
        fullWidth
        InputProps={{
            endAdornment: (
                <IconButton sx={{ display: value ? "inline-flex" : "none" }} onClick={onClick}>
                    <ClearIcon />
                </IconButton>
            ),
        }}
        sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
    >
        {ConvertToOptions(NotificationHours).map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
    </TextField>
);
