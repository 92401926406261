import React, { useContext, useMemo, useState } from 'react';

import {
    Badge, Box, Button,
    Chip, Divider,
    MenuItem,
    Select,
    Stack, TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from '@mui/material';

import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

import EditorBase from '../EditorBase';
import JourneyContext from '../JourneyContext';
import ProgressConditionsEditor from './ProgressConditionsEditor';
import { TextFieldDeferred } from './TextFieldDeferred';

const IncentiveEditor = ({ value, conditions, onChange, outcome, withRanks = false }) => {
    const [selectedNegative, setSelectedNegative] = useState(false);
    const [selectedType, setSelectedType] = useState("currency");
    const [selectedAmount, setSelectedAmount] = useState(null);
    const [selectedId, setSelectedId] = useState(null);

    const [newRankFromStr, setNewRankFromStr] = useState("1");
    const [newRankToStr, setNewRankToStr] = useState("100");

    const newRankRangeValid =
        !Number.isNaN(parseInt(newRankFromStr)) && !Number.isNaN(parseInt(newRankToStr)) &&
        (parseInt(newRankFromStr) > 0) && (parseInt(newRankToStr) > 0) &&
        (parseInt(newRankFromStr) <= parseInt(newRankToStr));

    const newRankFrom = parseInt(newRankFromStr);
    const newRankTo = parseInt(newRankToStr);

    const { allRewards: allIncentives } = useContext(JourneyContext);

    const withConditions = (outcome === 'progress') || (outcome === 'milestoneProgress');

    const toRankRangeKey = (rankFrom, rankTo) => {
        return `${rankFrom}:${rankTo}`;
    };

    const fromRankRangeKey = (rankRangeKey) => {
        const [rankFromStr, rankToStr] = rankRangeKey.split(':');

        const rankFrom = parseInt(rankFromStr);
        const rankTo = parseInt(rankToStr);

        return [Number.isNaN(rankFrom) ? null : rankFrom, Number.isNaN(rankTo) ? null : rankTo];
    };

    /**
     * Packs incentives into rank range groups, and aggregates incentives within each group.
     */
    const groupIncentives = (incentives) => {
        const groupedIncentives = new Map();

        incentives.forEach((incentive) => {
            const rankRangeKey = toRankRangeKey(incentive.rankFrom, incentive.rankTo);

            if (!groupedIncentives.has(rankRangeKey)) {
                groupedIncentives.set(rankRangeKey, new Map());
            }

            const rankRangeGroup = groupedIncentives.get(rankRangeKey);

            if (!rankRangeGroup.has(incentive.id)) {
                rankRangeGroup.set(incentive.id, {
                    id: incentive.id,
                    name: incentive.name,
                    rankFrom: incentive.rankFrom,
                    rankTo: incentive.rankTo,
                    amount: 0,
                    negative: false
                });
            }

            const incentiveBefore = rankRangeGroup.get(incentive.id);

            const amountBefore = !incentiveBefore.negative ? incentiveBefore.amount : -incentiveBefore.amount;

            let amountUpdate;

            switch (incentive.id) {
                case "currency":
                case "experience":
                    amountUpdate = !incentive.negative ? incentive.amount : -incentive.amount;
                    break;

                default:
                    amountUpdate = !incentive.negative ? 1 : -1;
                    break;
            }

            const amountAfter = amountBefore + amountUpdate;

            rankRangeGroup.set(incentive.id, { ...incentiveBefore, amount: Math.abs(amountAfter), negative: (amountAfter < 0) });
        });

        // Remove zero amounts

        for (const [rankRangeKey, rankRangeGroup] of groupedIncentives.entries()) {
            for (const [id, incentive] of rankRangeGroup)
                if (incentive.amount === 0)
                    rankRangeGroup.delete(id);

            if (rankRangeGroup.size === 0)
                groupedIncentives.delete(rankRangeKey);
        }

        // Convert rank range groups to lists

        for (const [rankRangeKey, rankRangeGroup] of groupedIncentives.entries()) {
            groupedIncentives.set(rankRangeKey, Array.from(rankRangeGroup.values()));
        }

        return groupedIncentives;
    };

    /**
     * Unpacks grouped and aggregated incentives into individual incentives.
     */
    const ungroupIncentives = (groupedIncentives) => {
        const result = [];

        for (const rankRangeList of groupedIncentives.values()) {
            for (const incentive of rankRangeList) {
                switch (incentive.id) {
                case "currency":
                case "experience":
                    result.push(incentive);
                    break;

                default:
                    for (let i = 0; i < incentive.amount; i++) {
                        result.push({
                            id: incentive.id,
                            name: incentive.name,
                            rankFrom: incentive.rankFrom,
                            rankTo: incentive.rankTo,
                            negative: incentive.negative
                        });
                    }
                    break;
                }
            }
        }

        return result;
    };

    const handleAddIncentive = (rankFrom = null, rankTo = null) => {
        let adjustmentIncentive;

        switch (selectedType) {
            case "currency":
                if (selectedAmount) {
                    adjustmentIncentive = {
                        id: "currency",
                        name: "Currency",
                        rankFrom: rankFrom,
                        rankTo: rankTo,
                        amount: parseInt(selectedAmount),
                        negative: selectedNegative
                    };
                }
                break;

            case "experience":
                if (selectedAmount) {
                    adjustmentIncentive = {
                        id: "experience",
                        name: "Experience",
                        rankFrom: rankFrom,
                        rankTo: rankTo,
                        amount: parseInt(selectedAmount),
                        negative: selectedNegative
                    };
                }
                break;

            case "badge":
                if (selectedId) {
                    adjustmentIncentive = {
                        id: selectedId,
                        name: allIncentives.find((i) => i.id === selectedId).name,
                        rankFrom: rankFrom,
                        rankTo: rankTo,
                        negative: selectedNegative
                    };
                }
                break;
        }

        if (!adjustmentIncentive) return;

        const updatedIncentives = ungroupIncentives(groupIncentives([...value, adjustmentIncentive]));

        onChange({ value: updatedIncentives, conditions });
    };

    const handleRemoveIncentive = (incentive) => {
        let adjustmentIncentive;

        switch (incentive.id) {
            case "currency":
            case "experience":
                // Remove currency/experience - all at once
                adjustmentIncentive = { ...incentive, negative: !incentive.negative };
                break;

            default:
                // Remove badge - one at a time
                adjustmentIncentive = { ...incentive, amount: 1, negative: !incentive.negative };
                break;
        }

        const updatedIncentives = ungroupIncentives(groupIncentives([...value, adjustmentIncentive]));

        onChange({ value: updatedIncentives, conditions });
    };

    const handleChangeRange = (rankFrom, rankTo, changedRankFrom, changedRankTo) => {
        const updatedIncentives = value.map((incentive) => {
            if ((incentive.rankFrom === rankFrom) && (incentive.rankTo === rankTo)) {
                return { ...incentive, rankFrom: changedRankFrom, rankTo: changedRankTo };
            } else {
                return incentive;
            }
        });

        onChange({ value: updatedIncentives, conditions });
    };

    const handleRemoveRange = (rankFrom, rankTo) => {
        const updatedIncentives = value.filter((incentive) => (incentive.rankFrom !== rankFrom) || (incentive.rankTo !== rankTo));
        onChange({ value: updatedIncentives, conditions });
    };

    const handleIncentiveModeChange = (event, value) => {
        if (value !== null) {
            setSelectedNegative(value === "penalty");
        }
    };

    const handleIncentiveTypeChange = (event, value) => {
        if (value !== null) {
            setSelectedType(value);
        }
    };

    const handleConditionsChange = (conditions) => {
        onChange({ value, conditions });
    };

    const summary = () => {
        return (value.length > 0) ? `${value.length} incentive(s)` : 'None';
    };

    const groupedIncentives = useMemo(() => groupIncentives(value), [value]);

    return (
        <EditorBase title="Incentives" summary={summary()}>
            <Stack spacing={6}>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1}>
                        <ToggleButtonGroup
                            size="small"
                            value={selectedNegative ? "penalty" : "reward"}
                            exclusive
                            onChange={handleIncentiveModeChange}
                            sx={{ flexGrow: 1 }}
                        >
                            <ToggleButton value="reward" sx={{ flexGrow: 1 }}>Reward</ToggleButton>
                            <ToggleButton value="penalty" sx={{ flexGrow: 1 }}>Penalty</ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                            size="small"
                            value={selectedType}
                            exclusive
                            onChange={handleIncentiveTypeChange}
                            sx={{ flexGrow: 1 }}
                        >
                            <ToggleButton value="currency" sx={{ flexGrow: 1 }}>Currency</ToggleButton>
                            <ToggleButton value="experience" sx={{ flexGrow: 1 }}>Experience</ToggleButton>
                            <ToggleButton value="badge" sx={{ flexGrow: 1 }}>Badge</ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                        {(selectedType !== "badge") && (
                            <TextField size="small" variant="outlined"
                                       label="Amount"
                                       type="number"
                                       value={selectedAmount || ""}
                                       onChange={(event) => setSelectedAmount(event.target.value)}
                                       InputLabelProps={{ shrink: true }}
                                       sx={{ flexGrow: 1 }}
                            />
                        )}

                        {(selectedType === "badge") && (
                            <Select
                                size="small"
                                label="Badge"
                                value={selectedId}
                                onChange={(event) => setSelectedId(event.target.value)}
                                sx={{ flexGrow: 1 }}
                            >
                                {
                                    allIncentives.filter((i) => (i.id !== "currency") && (i.id !== "experience")).map((i) => (
                                        <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        )}

                        {!withRanks && (
                            <Button size="small" variant="outlined" onClick={() => handleAddIncentive()}>Add</Button>
                        )}
                    </Stack>
                </Stack>

                {
                    Array.from(groupedIncentives.entries()).map(([rankRangeKey, rankRangeList]) => {
                        const [rankFrom, rankTo] = fromRankRangeKey(rankRangeKey);

                        return (
                            <IncentiveGroup
                                key={rankRangeKey}
                                withRanks={withRanks}
                                rankFrom={rankFrom} rankTo={rankTo} incentives={rankRangeList}
                                onAddIncentive={() => handleAddIncentive(rankFrom, rankTo)}
                                onRemoveIncentive={handleRemoveIncentive}
                                onChangeRange={(changedRankFrom, changedRankTo) => handleChangeRange(rankFrom, rankTo, changedRankFrom, changedRankTo)}
                                onRemoveRange={() => handleRemoveRange(rankFrom, rankTo)}
                            />
                        );
                    })
                }

                {withRanks && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <TextField
                            type="number"
                            label="Rank from" value={newRankFromStr}
                            size="small" sx={{ width: '100px' }} InputLabelProps={{ shrink: true }}
                            onChange={(event) => setNewRankFromStr(event.target.value)}
                            error={!newRankRangeValid}
                        />
                        <TextField
                            type="number"
                            label="Rank to" value={newRankToStr}
                            size="small" sx={{ width: '100px' }} InputLabelProps={{ shrink: true }}
                            onChange={(event) => setNewRankToStr(event.target.value)}
                            error={!newRankRangeValid}
                        />

                        <Divider sx={{ flexGrow: 1 }} />

                        <Button
                            size="small" variant="outlined"
                            onClick={() => handleAddIncentive(newRankFrom, newRankTo)}
                            disabled={!newRankRangeValid}>
                            Add to new range
                        </Button>
                    </Stack>
                )}

                {withConditions && (
                    <ProgressConditionsEditor value={conditions} onChange={handleConditionsChange} />
                )}
            </Stack>
        </EditorBase>
    );
};

const IncentiveGroup = (props) => {
    const {
        withRanks,
        rankFrom, rankTo, incentives,
        onAddIncentive, onRemoveIncentive,
        onChangeRange, onRemoveRange
    } = props;

    const rankRangeValid =
        !Number.isNaN(parseInt(rankFrom)) && !Number.isNaN(parseInt(rankTo)) &&
        (parseInt(rankFrom) > 0) && (parseInt(rankTo) > 0) &&
        (parseInt(rankFrom) <= parseInt(rankTo));

    const handleChangeRankFrom = (newValueStr) => {
        let newValue = parseInt(newValueStr);

        if (Number.isNaN(newValue)) {
            newValue = null;
        }

        onChangeRange(newValue, rankTo);
    };

    const handleChangeRankTo = (newValueStr) => {
        let newValue = parseInt(newValueStr);

        if (Number.isNaN(newValue)) {
            newValue = null;
        }

        onChangeRange(rankFrom, newValue);
    };

    return (
        <Stack spacing={2}>
            {withRanks && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <TextFieldDeferred
                        type="number"
                        label="Rank from" value={rankFrom}
                        size="small" sx={{ width: '100px' }} InputLabelProps={{ shrink: true }}
                        onChangeDeferred={handleChangeRankFrom}
                        error={!rankRangeValid}
                    />
                    <TextFieldDeferred
                        type="number"
                        label="Rank to" value={rankTo}
                        size="small" sx={{ width: '100px' }} InputLabelProps={{ shrink: true }}
                        onChangeDeferred={handleChangeRankTo}
                        error={!rankRangeValid}
                    />
                    <Divider sx={{ flexGrow: 1 }} />
                    <Button size="small" variant="outlined" onClick={onRemoveRange}>Remove range</Button>
                </Stack>
            )}

            <Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
                {
                    incentives.map((incentive) => {
                        const { id, name, amount, negative } = incentive;

                        const badge = `${!negative ? '+' : '-'}${amount}`;
                        const color = !negative ? "success" : "error";
                        const icon = !negative ? <ThumbUpAltIcon/> : <ThumbDownAltIcon/>;

                        return (
                            <Badge badgeContent={badge} max={Number.MAX_SAFE_INTEGER} color={color}>
                                <Chip key={id} variant="outlined" color={color} icon={icon} label={name} onDelete={() => onRemoveIncentive(incentive)} />
                            </Badge>
                        );
                    })
                }
            </Stack>

            {withRanks && (
                <Box>
                    <Button size="small" variant="text" onClick={onAddIncentive}>Add to this range</Button>
                </Box>
            )}
        </Stack>
    );
};

export default IncentiveEditor;
