import React, { useContext, useState } from 'react';
import EditorBase from '../EditorBase';
import JourneyContext from '../JourneyContext';
import {
    Avatar,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Menu,
    MenuItem,
    Select
} from '@mui/material';
import { Conjunction } from '../../../utils/enum';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ProfileImage from './ProfileImage';
import { newId } from '../convert';

const GroupHeaderItem = ({ conjunction, onConjunctionChange, onRemove }) => {
    return (
        <ListItem disableGutters>
            <Select size="small" value={conjunction} onChange={event => onConjunctionChange(event.target.value)}>
                <MenuItem value={Conjunction.Or}>Members of <span>&nbsp;</span><b>any</b><span>&nbsp;</span> of the following</MenuItem>
                <MenuItem value={Conjunction.And}>Members of <span>&nbsp;</span><b>all</b><span>&nbsp;</span> of the following</MenuItem>
            </Select>
            <ListItemSecondaryAction>
                <IconButton size="small" title="Remove this group" onClick={onRemove}><GroupRemoveIcon fontSize="small"/></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const ProfileItem = ({ profile, onRemove }) => {
    return (
        <ListItem disableGutters>
            <ListItemAvatar><Avatar><ProfileImage profile={profile} size="40"/></Avatar></ListItemAvatar>
            <ListItemText>{profile.name}</ListItemText>
            <ListItemSecondaryAction>
                <IconButton size="small" title="Remove this profile" onClick={onRemove}><PersonRemoveAlt1Icon fontSize="small"/></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const ProfileAddItem = ({ onAdd }) => {
    return (
        <ListItem disableGutters>
            <ListItemText>
                <Button size="small" variant="outlined" startIcon={<PersonAddAlt1Icon fontSize="small"/>} onClick={onAdd}>Add a profile</Button>
            </ListItemText>
        </ListItem>
    );
};

const ProfileGroup = ({ profiles, conjunction, onConjunctionChange, onProfileAdd, onProfileRemove, onRemove }) => {
    return (
        <>
            <GroupHeaderItem conjunction={conjunction} onConjunctionChange={onConjunctionChange} onRemove={onRemove} />
            <List sx={{ pl: 4, borderLeft: '1px dashed lightgray' }}>
                {
                    profiles.map((profile) => (
                        <ProfileItem key={profile.id} profile={profile} onRemove={() => onProfileRemove(profile)} />
                    ))
                }
                <ProfileAddItem onAdd={onProfileAdd} />
            </List>
        </>
    );
};

const ProfileGroupAddItem = ({ onAdd }) => {
    return (
        <ListItem disableGutters>
            <ListItemText>
                <Button size="small" variant="outlined" startIcon={<GroupAddIcon fontSize="small"/>} onClick={onAdd}>Add a group</Button>
            </ListItemText>
        </ListItem>
    );
};

const ParticipantEditor = ({ value, onChange }) => {
    const [menuGroup, setMenuGroup] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);

    const { allParticipants: allProfiles } = useContext(JourneyContext);

    const emptyGroup = () => {
        return {
            id: newId(),
            conjunction: Conjunction.And,
            childrenConjunction: Conjunction.And,
            participants: []
        };
    };

    if (value.length === 0) {
        value.push(emptyGroup());
    }

    const group1 = value;
    const firstGroup2 = group1[0];

    const everyone = (group1.length === 1) && (firstGroup2.participants.length === 0);
    const singleGroup = (group1.length === 1) && (firstGroup2.participants.length > 0);
    const multiGroup = (group1.length > 1);

    const beginAddProfile = (group, anchor) => {
        setMenuGroup(group);
        setMenuAnchor(anchor);
    };

    const endAddProfile = (profile) => {
        setMenuGroup(null);
        setMenuAnchor(null);

        menuGroup.participants.push(profile);
        onChange(value);
    };

    const closeMenu = () => {
        setMenuGroup(null);
        setMenuAnchor(null);
    };

    const removeProfile = (group, profile) => {
        group.participants = group.participants.filter((p) => (p.id !== profile.id));
        onChange(value);
    };

    const removeGroup1 = () => {
        value = [];  // TODO: or emptyGroup()?
        onChange(value);
    };

    const addGroup2 = () => {
        const group = emptyGroup();
        group.conjunction = firstGroup2.conjunction;

        value.push(group);  // TODO: push or recreate?

        onChange(value);
    };

    const removeGroup2 = (group) => {
        value = value.filter((g) => (g.id !== group.id));  // TODO: what if becomes empty?
        onChange(value);
    };

    const changeConjunction1 = (conjunction) => {
        value.forEach(g => g.conjunction = conjunction);
        onChange(value);
    };

    const changeConjunction2 = (group, conjunction) => {
        group.childrenConjunction = conjunction;
        onChange(value);
    };

    return (
        <EditorBase title="Participants" summary="">
            {everyone && (
                <List>
                    <ListItem disableGutters>
                        <ListItemText><b>Everyone</b></ListItemText>
                    </ListItem>
                    <ProfileAddItem onAdd={(event) => beginAddProfile(firstGroup2, event.currentTarget)} />
                </List>
            )}

            {singleGroup && (
                <List>
                    <ProfileGroup
                        profiles={firstGroup2.participants}
                        conjunction={firstGroup2.childrenConjunction}
                        onConjunctionChange={(conjunction) => changeConjunction2(firstGroup2, conjunction)}
                        onProfileAdd={(event) => beginAddProfile(firstGroup2, event.currentTarget)}
                        onProfileRemove={(profile) => removeProfile(firstGroup2, profile)}
                        onRemove={() => removeGroup2(firstGroup2)}
                    />
                    <ProfileGroupAddItem onAdd={addGroup2} />
                </List>
            )}

            {multiGroup && (
                <List>
                    <GroupHeaderItem conjunction={group1[0].conjunction} onConjunctionChange={changeConjunction1} onRemove={removeGroup1} />
                    <List sx={{ pl: 4, borderLeft: '1px dashed lightgray' }}>
                        {
                            group1.map((group2) => (
                                <ProfileGroup
                                    profiles={group2.participants}
                                    conjunction={group2.childrenConjunction}
                                    onConjunctionChange={(conjunction) => changeConjunction2(group2, conjunction)}
                                    onProfileAdd={(event) => beginAddProfile(group2, event.currentTarget)}
                                    onProfileRemove={(profile) => removeProfile(group2, profile)}
                                    onRemove={() => removeGroup2(group2)}
                                />
                            ))
                        }
                        <ProfileGroupAddItem onAdd={addGroup2} />
                    </List>
                </List>
            )}

            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
                {
                    allProfiles.map((profile) => (
                        <MenuItem key={profile.id} onClick={() => endAddProfile(profile)}>
                            <ListItemAvatar><Avatar><ProfileImage profile={profile} size="40"/></Avatar></ListItemAvatar>
                            <ListItemText primary={profile.name} />
                        </MenuItem>
                    ))
                }
            </Menu>
        </EditorBase>
    );
};

export default ParticipantEditor;
