import React from 'react';
import OutcomeView from '../controls/OutcomeView';

const RoundEndNodeView = ({ value, outcomeTypes }) => {
    const { messages, incentives } = value;

    return (
        <>
            <OutcomeView types={outcomeTypes} messages={messages} incentives={incentives} />
        </>
    );
};

export default RoundEndNodeView;
