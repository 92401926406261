import React from 'react';
import { Chip, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import OutcomeView from '../controls/OutcomeView';
import ScheduleIcon from '@mui/icons-material/Schedule';
import RepeatIcon from '@mui/icons-material/Repeat';

const RoundStartNodeView = ({ value, outcomeTypes }) => {
    const { timeframe, occurrences, messages, incentives } = value;

    const { type: timeframeType, intervalValue, intervalUnit, recurrentUnit } = timeframe;

    let timeframeDescription = null;

    if (timeframeType === "none") {
        timeframeDescription = (
            <>
                Complete anytime
            </>
        );
    }
    else if (timeframeType === "interval") {
        timeframeDescription = (
            <>
                Complete in {intervalValue} {intervalUnit}
            </>
        );
    }
    else if (timeframeType === "recurrent") {
        timeframeDescription = (
            <>
                Complete {recurrentUnit}
            </>
        );
    }

    return (
        <>
            <List dense disablePadding>
                <ListItem disableGutters disablePadding>
                    <ListItemIcon><ScheduleIcon/></ListItemIcon>
                    <ListItemText>{timeframeDescription}</ListItemText>
                </ListItem>

                <ListItem disableGutters disablePadding>
                    <ListItemIcon><RepeatIcon/></ListItemIcon>
                    <ListItemText>
                        <Stack direction="row" spacing={1}>
                            {(occurrences.perPlayer !== null) && (
                                <Chip size="small" label={occurrences.perPlayer + " / player"} variant="outlined" />
                            )}

                            {(occurrences.perPlayer === null) && (
                                <Chip size="small" label="∞ / player" variant="outlined" />
                            )}

                            {(occurrences.perJourney !== null) && (
                                <Chip size="small" label={occurrences.perJourney + " / journey"} variant="outlined" />
                            )}

                            {(occurrences.perJourney === null) && (
                                <Chip size="small" label="∞ / journey" variant="outlined" />
                            )}
                        </Stack>
                    </ListItemText>
                </ListItem>
            </List>

            <OutcomeView types={outcomeTypes} messages={messages} incentives={incentives} />
        </>
    );
};

export default RoundStartNodeView;
