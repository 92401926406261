import React, { useState, useMemo, useContext } from 'react';
import { Box } from "@mui/material";

import JourneyContext from '../JourneyContext';

import MilestoneNode from "./MilestoneNode";
import MilestoneGroupNode from "./MilestoneGroupNode";
import Node from "../Node";
import NodeTree from "../NodeTree";
import AddButtonGroup from '../controls/AddButtonGroup';
import {theme} from "../theme";
import { ExternalTreeBuilder } from '../ExternalTreeBuilder';
import { InternalTreeBuilder } from '../InternalTreeBuilder';

const createNodesEdges = (game, participantId) => {
    const internalTreeBuilder = new InternalTreeBuilder(game);
    internalTreeBuilder.selectParticipant(participantId);

    return internalTreeBuilder.buildJourneyNode();
};

const JourneyNode = (props) => {
    const {
        id,
        data: {
            reason,
            externalValue: { game, participantId },
            nodeProps
        }
    } = props;

    const { activeProfile, onNodeValueUpdated } = useContext(JourneyContext);

    const [{ nodes: initialNodes, edges: initialEdges }, setNodesEdges] = useState(createNodesEdges(game, participantId));

    const initWithMilestone = () => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            const nodeBuilderParams = externalTreeBuilder.root_initWithRule();

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneNode = internalTreeBuilder.create_MilestoneNode(nodeBuilderParams);

            setNodesEdges({
                nodes: [milestoneNode],
                edges: []
            });

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    const initWithGroup = () => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            const nodeBuilderParams = externalTreeBuilder.root_initWithParallelRuleUnion();

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneGroupNode = internalTreeBuilder.create_MilestoneGroupNode(nodeBuilderParams);

            setNodesEdges({
                nodes: [milestoneGroupNode],
                edges: []
            });

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    const handleChange = (nodes, edges) => {
        if (nodes.length === 0) {
            setNodesEdges({
                nodes: [],
                edges: []
            });
        }
    };

    // const addJourney = () => {
    //     const value = {};
    //     addRootNode(handle, "journey", value);
    // };

    const nodeTypes = useMemo(() => ({
        milestone: MilestoneNode,
        milestoneGroup: MilestoneGroupNode,
        // journey: JourneyNode
    }), []);

    return (
        <Node isContainer title="Journey Steps" reason={reason} {...nodeProps}>
            { (initialNodes.length === 0) && (
                <AddButtonGroup
                    onAddMilestone={initWithMilestone}
                    onAddGroup={initWithGroup}
                    // onAddJourney={addJourney}
                />
            )}
            { (initialNodes.length > 0) && (
                <NodeTree
                    nodeTypes={nodeTypes}
                    initialNodes={initialNodes} initialEdges={initialEdges}
                    onChange={handleChange}
                    offset={50} movable={false}
                />
            )}
        </Node>
    );
};

export default JourneyNode;
