import React from 'react';
import { Badge, Chip, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';

import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

const OutcomeIcon = ({ type }) => {
    switch (type) {
        case "journeyStart":
        case "start":
            return <PlayCircleFilledIcon/>;

        case "progress":
        case "milestoneProgress":
            return <PendingIcon/>;

        case "roundSuccess":
        case "success":
            return <CheckCircleIcon/>;

        case "roundFailure":
        case "failure":
            return <CancelIcon/>;

        case "journeyEnd":
            return <StopCircleIcon/>;

        default:
            return null;
    }
};

const IncentiveIcon = ({ id }) => {
    switch (id) {
        case "currency":
            return <AttachMoneyIcon/>;

        case "experience":
            return <StarBorderIcon/>;

        default:
            return <BookmarkBorderIcon/>;
    }
};

const IncentiveView = ({ incentive }) => {
    const { id, name, amount = 1, negative } = incentive;

    const badge = `${!negative ? '+' : '-'}${amount}`;
    const color = !negative ? "success" : "error";

    return (
        <Badge badgeContent={badge} max={Number.MAX_SAFE_INTEGER} color={color}>
            <Chip size="small" variant="outlined" color={color} icon={<IncentiveIcon id={id}/>} label={name} />
        </Badge>
    );
};

const OutcomeView = ({ types, messages, incentives }) => {
    const none = types.every(type => !messages[type] && (!incentives[type] || incentives[type].length === 0));

    if (none) {
        return (
            <>
                <Divider sx={{ my: 1 }}><Typography variant="caption">NO EVENTS</Typography></Divider>
            </>
        );
    }

    return (
        <>
            <Divider sx={{ my: 1 }}><Typography variant="caption">EVENTS</Typography></Divider>
            <List dense disablePadding>
                {
                    types.map((type) => {
                        const typeMessage = messages[type];
                        const typeIncentives = incentives[type];

                        if (!typeMessage && (!typeIncentives || typeIncentives.length === 0)) return null;

                        return (
                            <ListItem key={type} disableGutters disablePadding alignItems="flex-start">
                                <ListItemIcon><OutcomeIcon type={type}/></ListItemIcon>
                                <ListItemText>
                                    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                                        {typeMessage && (
                                            <Chip variant="outlined" size="small" icon={<MailOutlinedIcon/>} label={typeMessage.title} />
                                        )}

                                        {typeIncentives.map((incentive) => (
                                            <IncentiveView key={incentive.id} incentive={incentive} />
                                        ))}
                                    </Stack>
                                </ListItemText>
                            </ListItem>
                        );
                    })
                }
            </List>
        </>
    );
};

export default OutcomeView;
