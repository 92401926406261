import React from 'react';
import OutcomeView from '../controls/OutcomeView';

const JourneyEndNodeView = ({ value, outcomeTypes }) => {
    const { message, incentives } = value;

    return (
        <>
            <OutcomeView types={outcomeTypes} messages={{ journeyEnd: message }} incentives={{ journeyEnd: incentives }} />
        </>
    );
};

export default JourneyEndNodeView;
