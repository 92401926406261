import React from 'react';
import {Box, Typography} from "@mui/material";

import { theme } from "./theme"

const NodeTitle = ({ title }) => {
    return (
        <Box sx={{
            position: 'absolute', left: '1rem', top: 'calc(-0.5rem - 3px)',
            borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px',
            ...theme.nodes.controls
        }}>
            {(typeof title === 'string') && (
                <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold', px: 1 }}>{title}</Typography>
            )}

            {(typeof title !== 'string') && (
                title
            )}
        </Box>
    );
};

export default NodeTitle;
