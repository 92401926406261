import React, { useState } from 'react';

import { Stack, TextField, Typography } from "@mui/material";

import EditorBase from '../EditorBase';
import { TextFieldDeferred } from './TextFieldDeferred';

const NameDescriptionEditor = ({ value: { name, description }, onChange }) => {
    const summary = () => {
        return name;
    };

    const handleNameChange = (newValue) => {
        onChange({ name: newValue, description: description });
    };

    const handleDescriptionChange = (newValue) => {
        onChange({ name: name, description: newValue });
    };

    return (
        <EditorBase title="Name & Description" summary={summary()}>
            <Stack spacing={1}>
                <TextFieldDeferred
                    required
                    label="Name"
                    value={name || ""}
                    onChangeDeferred={handleNameChange}
                    fullWidth
                />
                <TextFieldDeferred
                    label="Description"
                    value={description || ""}
                    onChangeDeferred={handleDescriptionChange}
                    fullWidth
                    multiline
                />
            </Stack>
        </EditorBase>
    );
};

export default NameDescriptionEditor;

