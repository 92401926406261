import React, { useState, useEffect, useContext, useMemo } from 'react';
import NodeTitle from "./NodeTitle";
import NodeControls from "./NodeControls";
import { Handle, Position, useReactFlow, useNodeId } from 'reactflow';
import NodeHandle from "./NodeHandle";
import { Box } from "@mui/material";
import {
    getOutgoingFromHandle,
    getOutgoing,
    hasOutgoingFromHandle,
    hasOutgoing,
    getDescendingFromHandle,
    hasIncoming,
    getIncoming, getDescending
} from './utils/reactflow';

import JourneyContext from './JourneyContext';
import { ExternalTreeBuilder } from './ExternalTreeBuilder';
import { InternalTreeBuilder } from './InternalTreeBuilder';
import { removeOrphanIncentives, removeOrphanMessages } from './convert';
import { theme } from './theme';


const Node = ({
        isContainer = false,
        title, reason, isConnectable = true,
        hasModes = true, initialMode = "view", onEditMode, onViewMode,
        isRemoveable = true,
        children
    }) => {

    const { onNodeValueUpdated } = useContext(JourneyContext);

    let [mode, setMode] = useState(initialMode);

    if (!hasModes) {
        mode = "edit";
    }

    const rf = useReactFlow();

    const thisNodeId = useNodeId();
    const thisNode = rf.getNode(thisNodeId);

    const isStart = !hasIncoming(rf, thisNode);
    const isEnd = !hasOutgoing(rf, thisNode);

    const onEditModeInternal = () => {
        setMode("edit");
        if (onEditMode) onEditMode();
    };

    const onViewModeInternal = () => {
        setMode("view");
        if (onViewMode) onViewMode();
    };

    const onCollapseExpandDescendants = (handle, collapsed) => {
        const [descendantNodes, descendantEdges] = getDescendingFromHandle(rf, thisNode, handle);

        const descendantNodeIds = new Set(descendantNodes.map(n => n.id));
        const descendantEdgeIds = new Set(descendantEdges.map(e => e.id));

        const nodes = rf.getNodes();
        const edges = rf.getEdges();

        nodes.forEach(n => {
            if (descendantNodeIds.has(n.id)) {
                n.hidden = collapsed;
            }
        });

        edges.forEach(e => {
            if (descendantEdgeIds.has(e.id)) {
                e.hidden = collapsed;
            }
        });

        rf.setNodes(nodes);
        rf.setEdges(edges);
    };

    const onCollapseDescendants = (handle) => {
        onCollapseExpandDescendants(handle, true);
    };

    const onExpandDescendants = (handle) => {
        onCollapseExpandDescendants(handle, false);
    };

    const addNode = (newNode, internalTreeBuilder) => {
        const newNodeReason = newNode.data.reason;

        const [outgoingNode, outgoingEdge] = getOutgoingFromHandle(rf, thisNode, newNodeReason);

        // Add new node

        newNode.position = { x: thisNode.position.x, y: thisNode.position.y };
        rf.addNodes([newNode]);

        // Connect this node (as source) to the new node (as target) via the specified handle.
        rf.addEdges(internalTreeBuilder.connectNodes([thisNode, newNode]));

        if (outgoingNode !== null) {
            // Connect the new node (as source) to the outgoing node (as target) already connected via the specified handle.
            // The new node will be connected to the existing outgoing node via the default (success) handle.

            outgoingNode.data.reason = "success";
            rf.addEdges(internalTreeBuilder.connectNodes([newNode, outgoingNode]));

            // Delete the old edge connecting this node to the outgoing node
            rf.deleteElements({ nodes: null, edges: [outgoingEdge] });
        }
    };

    const removeNode = () => {
        // Things to remove:
        // - the edge to the parent node (if any)
        // - this node
        // - all descending nodes/edges

        const [incomingNodes, incomingEdges] = getIncoming(rf, thisNode);
        const [descendingNodes, descendingEdges] = getDescending(rf, thisNode);

        rf.deleteElements({
            nodes: [thisNode, ...descendingNodes],
            edges: [...incomingEdges, ...descendingEdges]
        });
    };

    // TODO: maybe move this to MilestoneNode?
    const onAddMilestone = (reason) => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            let failure = undefined;

            if (reason === "success")
                failure = false;
            else if (reason === "failure")
                failure = true;

            let nodeBuilderParams;

            if (thisNode.type === "milestone") {
                nodeBuilderParams = externalTreeBuilder.rule_addNextRule(thisNode.data.externalValue.rule, failure);
            }
            else if (thisNode.type === "milestoneGroup") {
                nodeBuilderParams = externalTreeBuilder.parallelRuleUnion_addNextRule(thisNode.data.externalValue.baseUnion, failure);
            }

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneNode = internalTreeBuilder.create_MilestoneNode(nodeBuilderParams, reason);

            addNode(milestoneNode, internalTreeBuilder);

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    // TODO: maybe move this to MilestoneGroupNode?
    const onAddGroup = (reason) => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            let failure = undefined;

            if (reason === "success")
                failure = false;
            else if (reason === "failure")
                failure = true;

            let nodeBuilderParams;

            if (thisNode.type === "milestone") {
                nodeBuilderParams = externalTreeBuilder.rule_addNextParallelRuleUnion(thisNode.data.externalValue.rule, failure);
            }
            else if (thisNode.type === "milestoneGroup") {
                nodeBuilderParams = externalTreeBuilder.parallelRuleUnion_addNextParallelRuleUnion(thisNode.data.externalValue.baseUnion, failure);
            }

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneGroupNode = internalTreeBuilder.create_MilestoneGroupNode(nodeBuilderParams, reason);

            addNode(milestoneGroupNode, internalTreeBuilder);

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };


    const onRemove = () => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            if (thisNode.type === "milestone") {
                externalTreeBuilder.rule_prune(thisNode.data.externalValue.rule);
            }
            else if (thisNode.type === "milestoneGroup") {
                externalTreeBuilder.parallelRuleUnion_prune(thisNode.data.externalValue.baseUnion);
            }

            removeOrphanMessages(externalTreeBuilder.game);
            removeOrphanIncentives(externalTreeBuilder.game);

            // Building internal tree

            removeNode();

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId
            };

            return updatedExternalValue;
        });
    };


    // const onAddJourney = (handle) => {
    //     const value = {};
    //     addNode(handle, "journey", value);
    // };

    // TODO: review this
    const reasons = useMemo(() => {
        return (title === "Round End") ? ["roundSuccess", "roundFailure", /* "roundCancel" */] : ["success", "failure", /* "cancel" */];
    }, []);

    const [editModeChildren, viewModeChildren] = React.Children.toArray(children);

    const regularStyle = {
        width: (mode === "view" ? '300px' : '500px'),
        ...theme.nodes.body.regular
    };

    const containerStyle = {
        minWidth: (mode === "view" ? '300px' : '500px'),
        minHeight: (mode === "view" ? '150px' : '250px'),
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        ...theme.nodes.body.container
    };

    return (
        <Box style={isContainer ? containerStyle : regularStyle}>
            <NodeTitle title={title} />

            {hasModes && (
                <NodeControls
                    onEditMode={onEditModeInternal}
                    onViewMode={onViewModeInternal}
                    onRemove={isRemoveable ? onRemove : undefined}
                />
            )}

            <Box>
                {!hasModes && children}
                {hasModes && onEditMode && onViewMode && children}
                {hasModes && !onEditMode && !onViewMode && (mode === "edit") && editModeChildren}
                {hasModes && !onEditMode && !onViewMode && (mode === "view") && viewModeChildren}
            </Box>

            {isConnectable && (
                <>
                    {!isStart && (
                        <NodeHandle type="target" reason={reason} mode={mode} position={Position.Top} />
                    )}

                    {
                        reasons.map((reason) => (
                            <NodeHandle key={reason}
                                        id={reason} type="source" reason={reason} mode={mode} position={Position.Bottom}
                                        connected={hasOutgoingFromHandle(rf, thisNode, reason)}
                                        onCollapse={() => onCollapseDescendants(reason)}
                                        onExpand={() => onExpandDescendants(reason)}
                                        onAddMilestone={() => onAddMilestone(reason)}
                                        onAddGroup={() => onAddGroup(reason)}
                                        // onAddJourney={() => onAddJourney(reason)}
                            />
                        ))
                    }
                </>
            )}

            {!isConnectable && (
                <>
                    {!isStart && (
                        <Handle type="target" position={Position.Top} />
                    )}
                    {!isEnd && (
                        <Handle type="source" position={Position.Bottom} />
                    )}
                </>
            )}
        </Box>
    );
};

export default Node;
