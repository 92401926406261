import React, { useState } from 'react';

import { Box, Divider, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

import MessageEditor from "./MessageEditor";
import IncentiveEditor from "./IncentiveEditor";

const OutcomeEditor = (props) => {
    const {
        types, unionRuleId,
        messages, onMessageChange,
        incentives, withIncentiveRanks = false, onIncentiveChange,
        incentivesProgressConditions, onIncentivesProgressConditionsChange,
        options: { enableMessages = true, enableIncentives = true } = {}
    } = props;

    const [outcome, setOutcome] = useState(types[0]);

    const outcomeMessage = messages[outcome];
    const outcomeIncentives = incentives[outcome];

    const handleOutcomeChange = (event, value) => {
        if (value !== null) {
            setOutcome(value);
        }
    };

    const handleMessageUpdate = (value) => {
        onMessageChange({ ...messages, [outcome]: value });
    };

    const handleMessageDelete = () => {
        onMessageChange({ ...messages, [outcome]: null });
    };

    const handleIncentiveChange = ({ value, conditions }) => {
        onIncentiveChange({ ...incentives, [outcome]: value });

        if (outcome === 'progress' || outcome === 'milestoneProgress') {
            onIncentivesProgressConditionsChange({ conditions });
        }
    };

    const formatType = (type) => {
        return type
            .replace('journeyStart', 'start')
            .replace('journeyEnd', 'end')
            .replace('roundSuccess', 'success')
            .replace('roundFailure', 'failure')
            // .replace('roundCancel', 'cancel')
            .replace('milestoneProgress', 'progress');
    };

    return (
        <>
            <Divider sx={{ my: 2 }}>
                {(types.length > 1) && (
                    <ToggleButtonGroup exclusive value={outcome} onChange={handleOutcomeChange} size="small" className="nodrag nopan">
                        {
                            types.map((type) => (
                                <ToggleButton key={type} value={type}>{`on ${formatType(type)}`}</ToggleButton>
                            ))
                        }
                    </ToggleButtonGroup>
                )}
                {(types.length === 1) && (
                    <Typography variant="button">{`on ${formatType(types[0])}`}</Typography>
                )}
            </Divider>

            <Box key={outcome}>
                {enableMessages && (
                    <MessageEditor value={outcomeMessage} onUpdate={handleMessageUpdate} onDelete={handleMessageDelete} type={outcome} unionRuleId={unionRuleId} />
                )}
                {enableIncentives && (
                    <IncentiveEditor value={outcomeIncentives} conditions={incentivesProgressConditions?.conditions} onChange={handleIncentiveChange} outcome={outcome} withRanks={withIncentiveRanks} />
                )}
            </Box>
        </>
    );
};

export default OutcomeEditor;
