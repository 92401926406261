import React, { useState } from 'react';

import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditorBase = ({ title, summary, children }) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} className="nodrag nopan">
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography sx={{ width: '50%' }}>{title}</Typography>
                <Typography sx={{ color: 'text.secondary' }}>{summary}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default EditorBase;
