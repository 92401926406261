import { Operator } from '../../../utils/enum';

const getTextForAiPrompt = (currency, experience, badges) => {
    const items = [];

    if (currency) {
        items.push(`${currency} currency units`);
    }

    if (experience) {
        items.push(`${experience} experience points`);
    }

    if (badges) {
        badges.forEach(b => items.push(`the "${b}" badge`));
    }

    return items.join(', ');
};


export const getRewardsAndPenalties = (rewardUnions, unionRuleId) => {
    let rewardCurrency = 0, rewardExperience = 0;
    let penaltyCurrency = 0, penaltyExperience = 0;
    let rewardBadges = [];
    let penaltyBadges = [];

    for (let rewardUnion of rewardUnions) {
        if (rewardUnion.unionRuleId !== unionRuleId) continue;

        if (rewardUnion.currency > 0) {
            rewardCurrency += rewardUnion.currency;
        }
        else if (rewardUnion.currency < 0) {
            penaltyCurrency += rewardUnion.currency;
        }

        if (rewardUnion.experience > 0) {
            rewardExperience += rewardUnion.experience;
        }
        else if (rewardUnion.experience < 0) {
            penaltyExperience += rewardUnion.experience;
        }

        for (let reward of rewardUnion.rewards) {
            if (reward.negative) {
                penaltyBadges.push(reward.name);
            }
            else {
                rewardBadges.push(reward.name);
            }
        }
    }

    let rewards = getTextForAiPrompt(rewardCurrency, rewardExperience, rewardBadges);
    let penalties = getTextForAiPrompt(penaltyCurrency, penaltyExperience, penaltyBadges);

    return { rewards, penalties };
};


export const getProgressRatio = (messageConditions, ruleUnions, ruleDetails) => {
    const suitableMessageConditions = messageConditions.filter(mc => mc.operator === Operator.GreaterThan || mc.operator === Operator.GreaterOrEqualTo);

    for (let messageCondition of suitableMessageConditions) {
        if (!messageCondition.unionRuleId) continue;

        const matchingRules = ruleUnions.flatMap(ru => ru.rules).filter(r => r.unionEntityId === messageCondition.unionRuleId);

        for (let r of matchingRules) {
            const rule = ruleDetails[r.id];
            if (rule) {
                const suitableRuleConditions = rule.conditions.filter(rc => rc.operator === Operator.GreaterThan || rc.operator === Operator.GreaterOrEqualTo);
                const matchingRuleConditions = suitableRuleConditions.filter(rc => rc.actionTypeFieldId === messageCondition.actionTypeFieldId);

                if (matchingRuleConditions.length > 0) {
                    return messageCondition.value / matchingRuleConditions[0].value;
                }
            }
        }
    }

    return null;
};
