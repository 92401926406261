import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import OutcomeEditor from '../controls/OutcomeEditor';
import JourneyContext from '../JourneyContext';

const RoundEndNodeEdit = ({ value, onChange, outcomeTypes }) => {
    const { messages, incentives } = value;

    const { isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    const setMessages = (messages) => {
        onChange({ ...value, messages });
    };

    const setIncentives = (incentives) => {
        onChange({ ...value, incentives });
    };

    return (
        <Stack useFlexGap>
            <OutcomeEditor types={outcomeTypes}
                           messages={messages} onMessageChange={setMessages}
                           incentives={incentives} withIncentiveRanks={isLeaderboardMode} onIncentiveChange={setIncentives} />
        </Stack>
    );
};

export default RoundEndNodeEdit;
