import React, { useContext, useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import {
    Box
} from '@mui/material';
import JourneyContext from '../JourneyContext';
import Node from "../Node";
import NodeEditMode from "../NodeEditMode";
import NodeViewMode from "../NodeViewMode";
import {theme} from "../theme";
import RoundStartNodeEdit from './RoundStartNodeEdit';
import RoundStartNodeView from './RoundStartNodeView';
import { applyToExternalValue_RoundStartNode } from '../convert';

const RoundStartNode = (props) => {
    const {
        id,
        data: {
            externalValue,
            internalValue,
            nodeProps
        }
    } = props;

    const rf = useReactFlow();

    const { isGameMode, isLeaderboardMode, onNodeValueUpdated } = useContext(JourneyContext);

    const outcomeTypes = useMemo(() => {
        if (isGameMode)
            return ["start", "progress"];
        else if (isLeaderboardMode)
            return ["start"];
    }, []);

    const updateValue = (updatedInternalValue) => {
        onNodeValueUpdated((currentExternalValue) => {
            const updatedExternalValue = applyToExternalValue_RoundStartNode(updatedInternalValue, currentExternalValue);

            rf.setNodes((nodes) => nodes.map((node) => {
                if (node.id !== id) return node;

                return {
                    ...node,
                    data: {
                        ...node.data,
                        internalValue: updatedInternalValue,
                        externalValue: updatedExternalValue
                    }
                };
            }));

            return updatedExternalValue;
        });
    };

    return (
        <Node title="Round Start" isRemoveable={false} isConnectable={false} {...nodeProps}>
            <NodeEditMode>
                <Box sx={{ mx: 2, my: 6 }}>
                    <RoundStartNodeEdit value={internalValue} onChange={updateValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeEditMode>
            <NodeViewMode>
                <Box sx={{ mx: 2, my: 3 }}>
                    <RoundStartNodeView value={internalValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeViewMode>
        </Node>
    );
};

export default RoundStartNode;
