import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { toast } from 'react-toastify';
import configureStore from './store/configureStore';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });

// Get the application-wide store instance,
// prepopulating with state from the server where available.
const initialState = window.initialReduxState;
const store = configureStore(history, initialState);
const persistor = persistStore(store);

const rootElement = document.getElementById('root');


// This fixes the issue "ResizeObserver loop limit exceeded"
class CalmResizeObserver extends ResizeObserver {
  constructor(callback) {
    super((entries, observer) => {
      requestAnimationFrame(() => {
        callback(entries, observer);
      });
    });
  }
}
window.ResizeObserver = CalmResizeObserver;


const onUpdate = () => {
  store.dispatch({
    type: toast.TYPE.WARNING,
    description: 'GZone has been upgraded! Please refresh!',
  });
};

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  rootElement,
);

registerServiceWorker(onUpdate);

export default store;
