import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import TimeframeEditor from '../controls/TimeframeEditor';
import OccurrenceEditor from '../controls/OccurrenceEditor';
import OutcomeEditor from '../controls/OutcomeEditor';
import JourneyContext from '../JourneyContext';

const RoundStartNodeEdit = ({ value, onChange, outcomeTypes }) => {
    const { timeframe, occurrences, messages, incentives, incentivesProgressConditions } = value;

    const setTimeframe = (timeframe) => {
        onChange({ ...value, timeframe });
    };

    const setOccurrences = (occurrences) => {
        onChange({ ...value, occurrences });
    };

    const setMessages = (messages) => {
        onChange({ ...value, messages });
    };

    const setIncentives = (incentives) => {
        onChange({ ...value, incentives });
    };

    const setIncentivesProgressConditions = (incentivesProgressConditions) => {
        onChange({ ...value, incentivesProgressConditions });
    };

    return (
        <Stack useFlexGap>
            <TimeframeEditor
                type={timeframe.type}
                value={timeframe.value}
                onChange={setTimeframe}
                options={{
                    enableNone: true,
                    enableDuration: true,
                    enableCalendar: false,
                    durationOptions: { enableAlign: true, enableAwait: true },
                    calendarOptions: undefined
                }}
            />

            <OccurrenceEditor value={occurrences} onChange={setOccurrences} />

            <OutcomeEditor types={outcomeTypes}
                messages={messages} onMessageChange={setMessages}
                incentives={incentives} onIncentiveChange={setIncentives}
                incentivesProgressConditions={incentivesProgressConditions} onIncentivesProgressConditionsChange={setIncentivesProgressConditions} />
        </Stack>
    );
};

export default RoundStartNodeEdit;
