import React, { useState } from 'react';

import { Stack, TextField, Typography } from "@mui/material";

import EditorBase from '../EditorBase';
import { TextFieldDeferred } from './TextFieldDeferred';

const OccurrenceEditor = ({ value: { perPlayer, perJourney }, onChange }) => {
    const summary = () => {
        if (perPlayer === null && perJourney === null) {
            return "Unlimited";
        }
        else if (perPlayer !== null && perJourney === null) {
            return perPlayer + " / Unlimited";
        }
        else if (perPlayer === null && perJourney !== null) {
            return "Unlimited / " + perJourney;
        }
        else {
            return perPlayer + " / " + perJourney;
        }
    };

    const formatValue = (value) => {
        if (value === null) {
            return "";
        }
        else {
            return value;
        }
    };

    const parseValue = (value) => {
        if (value === "") {
            return null;
        }
        else if (value < 1) {
            return 1;
        }
        else {
            return value;
        }
    };

    const handlePerPlayerChange = (newValue) => {
        onChange({ perPlayer: parseValue(newValue), perJourney: perJourney });
    };

    const handlePerJourneyChange = (newValue) => {
        onChange({ perPlayer: perPlayer, perJourney: parseValue(newValue) });
    };

    return (
        <EditorBase title="Occurrences" summary={summary()}>
            <Stack direction="row" spacing={1} alignItems="center">
                <TextFieldDeferred
                    label="Per player"
                    type="number"
                    placeholder="Unlimited"
                    value={formatValue(perPlayer)}
                    onChangeDeferred={handlePerPlayerChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <TextFieldDeferred
                    label="Per journey"
                    type="number"
                    placeholder="Unlimited"
                    value={formatValue(perJourney)}
                    onChangeDeferred={handlePerJourneyChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </EditorBase>
    );
};

export default OccurrenceEditor;
