import React, { useContext, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditorBase from '../EditorBase';
import JourneyContext from '../JourneyContext';
import {
    Button,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    FormControl,
    Stack,
    TextField,
    MenuItem,
    Typography,
} from '@mui/material';
import { actionCreators as ruleActionCreators } from '../../../store/modules/Rule';
import { actionCreators as rankingActionCreators } from '../../../store/modules/Ranking';
import {
    Aggregate,
    Operator,
} from '../../../utils/enum';
import ClearIcon from '@mui/icons-material/Clear';
import AddCircle from '@mui/icons-material/AddCircle';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { TextFieldDeferred } from './TextFieldDeferred';

const ProgressConditionsEditor = (props) => {
    const { onChange, requestGetRule, requestGetRanking, ruleDetails, rankingDetails } = props;
    let { value } = props;

    if (!value) {
        value = [];
    }

    const { activeProfileGame, isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    useEffect(() => {
        if (isGameMode) {
            const gameRuleIds = new Set(activeProfileGame.ruleUnions.flatMap(ru => ru.rules).map(r => r.id).filter(id => id !== null));
            const knownRuleIds = new Set(Object.values(ruleDetails).map(r => r.id));
            const unknownRuleIds = [...gameRuleIds].filter(id => !knownRuleIds.has(id));
            unknownRuleIds.forEach(id => requestGetRule(id));
        }
        else if (isLeaderboardMode) {
            const gameRankingIds = new Set(activeProfileGame.ruleUnions.flatMap(ru => ru.rules).map(r => r.id).filter(id => id !== null));
            const knownRankingIds = new Set(Object.values(rankingDetails).map(r => r.id));
            const unknownRankingIds = [...gameRankingIds].filter(id => !knownRankingIds.has(id));
            unknownRankingIds.forEach(id => requestGetRanking(id));
        }
    }, [activeProfileGame.ruleUnions]);

    const unionRules = activeProfileGame.ruleUnions.flatMap(ru => ru.rules).map(r => ({ label: r.name, value: r.unionEntityId, ruleId: r.id }));

    const operators = [
        { value: Operator.GreaterThan, label: Operator[Operator.GreaterThan] },
        { value: Operator.GreaterOrEqualTo, label: Operator[Operator.GreaterOrEqualTo] },
        { value: Operator.LessThan, label: Operator[Operator.LessThan] },
        { value: Operator.LessOrEqualTo, label: Operator[Operator.LessOrEqualTo] }
    ];

    const handleRuleChange = (event, condition) => {
        condition.unionRuleId = event.target.value;
        onChange(value);
    };

    const handleClearRuleClick = (condition) => {
        condition.unionRuleId = null;
        onChange(value);
    };

    const handleRuleConditionChange = (event, condition) => {
        condition.ruleActionTypeFieldId = event.target.value;
        onChange(value);
    };

    const handleClearRuleConditionClick = (condition) => {
        condition.ruleActionTypeFieldId = null;
        onChange(value);
    };

    const handleOperatorChange = (event, condition) => {
        condition.operator = event.target.value;
        onChange(value);
    };

    const handleClearOperatorClick = (condition) => {
        condition.operator = null;
        onChange(value);
    };

    const handleValueChange = (newValue, condition) => {
        condition.value = newValue;
        onChange(value);
    };

    const removeCondition = (index) => {
        value.splice(index, 1)
        onChange(value);
    };

    const emptyCondition = () => {
        return {
            unionRuleId: null,
            ruleActionTypeFieldId: null,
            aggregate: null,
            operator: null,
            value: null
        };
    };

    const addCondition = () => {
        const condition = emptyCondition();

        value.push(condition);  // TODO: push or recreate?

        onChange(value);
    };

    const summary = () => {
        return (value.length > 0) ? `${value.length} condition(s)` : 'None';
    };

    return (
        <EditorBase title="Conditions *" summary={summary()}>
            <List>
                {
                    value.map((condition, index) => (
                        <FormControl fullWidth>
                            <ConditionHeaderItem index={index} onRemove={() => removeCondition(index)} />
                            <Stack spacing={2} sx={{ pl: 4, borderLeft: '1px dashed lightgray' }}>
                                <RuleSelect
                                    value={condition.unionRuleId}
                                    onChange={(event) => handleRuleChange(event, condition)}
                                    label="Rule *"
                                    onClick={() => handleClearRuleClick(condition)}
                                    unionRules={unionRules}
                                />
                                <RuleConditionSelect
                                    value={condition.ruleActionTypeFieldId}
                                    onChange={(event) => handleRuleConditionChange(event, condition)}
                                    label="Rule Condition *"
                                    onClick={() => handleClearRuleConditionClick(condition)}
                                    condition={condition}
                                    activeProfileGame={activeProfileGame}
                                    ruleDetails={ruleDetails}
                                />
                                <Stack direction="row" spacing={1}>
                                    <OperatorSelect
                                        value={condition.operator}
                                        onChange={(event) => handleOperatorChange(event, condition)}
                                        label="Operator *"
                                        onClick={() => handleClearOperatorClick(condition)}
                                        operators={operators}
                                    />
                                    <TextFieldDeferred
                                        variant="outlined"
                                        label="Value"
                                        type="number"
                                        value={condition.value || ""}
                                        onChangeDeferred={(newValue) => handleValueChange(newValue, condition)}
                                        required
                                    />
                                </Stack>
                            </Stack>
                        </FormControl>
                    ))
                }
                <ConditionAddItem onAdd={addCondition} />
            </List>
        </EditorBase>
    );
};

const RuleSelect = ({
    value,
    onChange,
    label,
    onClick,
    unionRules,
}) => {
    return (
        <TextField
            value={value || ""}
            onChange={onChange}
            select
            label={label}
            fullWidth
            InputProps={{
                endAdornment: (
                    <IconButton sx={{ display: value ? "inline-flex" : "none" }} onClick={onClick}>
                        <ClearIcon />
                    </IconButton>
                ),
            }}
            sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
        >
            {unionRules.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
        </TextField>
    );
};

const RuleConditionSelect = ({
    value,
    onChange,
    label,
    onClick,
    condition,
    activeProfileGame,
    ruleDetails,
}) => {
    const ruleConditions = [];

    if (condition.unionRuleId) {
        const rules = activeProfileGame.ruleUnions.flatMap(ru => ru.rules).filter(r => r.unionEntityId === condition.unionRuleId);

        rules.forEach(r => {
            const rule = ruleDetails[r.id];
            if (rule) {
                rule.conditions.forEach(ruleCondition => {
                    ruleConditions.push({ label: `${Aggregate[ruleCondition.aggregate]} of ${ruleCondition.actionTypeFieldName}`, value: ruleCondition.id });
                });
            }
        });
    }

    return (
        <TextField
            value={value || ""}
            onChange={onChange}
            select
            label={label}
            fullWidth
            InputProps={{
                endAdornment: (
                    <IconButton sx={{ display: value ? "inline-flex" : "none" }} onClick={onClick}>
                        <ClearIcon />
                    </IconButton>
                ),
            }}
            sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
        >
            {ruleConditions.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
        </TextField>
    );
};

const OperatorSelect = ({
    value,
    onChange,
    label,
    onClick,
    operators,
}) => {
    return (
        <TextField
            value={value || ""}
            onChange={onChange}
            select
            label={label}
            fullWidth
            InputProps={{
                endAdornment: (
                    <IconButton sx={{ display: value ? "inline-flex" : "none" }} onClick={onClick}>
                        <ClearIcon />
                    </IconButton>
                ),
            }}
            sx={{ "& .Mui-focused .MuiIconButton-root": { color: "primary.main" } }}
        >
            {operators.map((option) => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
        </TextField>
    );
};

const ConditionHeaderItem = ({ index, onRemove }) => {
    return (
        <ListItem disableGutters>
            <Typography>Condition #{index + 1}</Typography>
            <ListItemSecondaryAction>
                <IconButton size="small" title="Remove this condition" onClick={onRemove}><RemoveCircle fontSize="small" /></IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const ConditionAddItem = ({ onAdd }) => {
    return (
        <ListItem disableGutters>
            <ListItemText>
                <Button size="small" variant="outlined" startIcon={<AddCircle fontSize="small" />} onClick={onAdd}>Add a condition</Button>
            </ListItemText>
        </ListItem>
    );
};

ProgressConditionsEditor.propTypes = {
    requestGetRule: PropTypes.func.isRequired,
    requestGetRanking: PropTypes.func.isRequired
};

export default connect(
    (state, ownProps) => ({
        ruleDetails: state.rule.details,
        rankingDetails: state.ranking.details
    }),
    (dispatch, ownProps) => bindActionCreators(
        {
            requestGetRule: ruleActionCreators.requestGet,
            requestGetRanking: rankingActionCreators.requestGet
        },
        dispatch
    ),
)(ProgressConditionsEditor);
