export const DataType = {
  1: 'Text',
  2: 'Number',
  3: 'Boolean',
  4: 'Date',
  5: 'Enum',
  Text: 1,
  Number: 2,
  Boolean: 3,
  Date: 4,
  Enum: 5,
  DateOnly: 104,
};

export const Aggregate = {
  1: 'Sum',
  2: 'Count',
  3: 'Average',
  4: 'Minimum',
  5: 'Maximum',
  Sum: 1,
  Count: 2,
  Average: 3,
  Minimum: 4,
  Maximum: 5,
};

export const Operator = {
  1: 'Equal To',
  2: 'Not Equal To',
  3: 'Contains',
  4: 'Does Not Contain',
  5: 'Greater Than',
  6: 'Greater Or Equal To',
  7: 'Less Than',
  8: 'Less Or Equal To',
  9: 'Weekday Is',
  10: 'Weekday Is Not',
  11: 'Month Is',
  12: 'Month Is Not',
  13: 'Day Is',
  14: 'Day Is Not',
  15: 'Hour Is',
  16: 'Hour Is Not',
  17: 'Year Is',
  18: 'Year Is Not',
  101: 'Sort',
  EqualTo: 1,
  NotEqualTo: 2,
  Contains: 3,
  DoesNotContain: 4,
  GreaterThan: 5,
  GreaterOrEqualTo: 6,
  LessThan: 7,
  LessOrEqualTo: 8,
  WeekdayIs: 9,
  WeekdayIsNot: 10,
  MonthIs: 11,
  MonthIsNot: 12,
  DayIs: 13,
  DayIsNot: 14,
  HourIs: 15,
  HourIsNot: 16,
  YearIs: 17,
  YearIsNot: 18,
  Sort: 101,
};

export const Weekday = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

export const Month = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

export const IntegrationType = {
  1: 'MZone Trip',
  2: 'D-Insight Daily',
  3: 'D-Insight Trip',
  4: 'MZone Daily',
  MZoneTrip: 1,
  DInsightDaily: 2,
  DInsightTrip: 3,
  MZoneDaily: 4,
};

export const Conjunction = {
  1: 'And',
  2: 'Or',
  3: 'Then',
  And: 1,
  Or: 2,
  Then: 3,
};

export const GameElement = {
  1: 'Rules',
  2: 'Profiles',
  3: 'Rewards',
  4: 'Games',
  Rules: 1,
  Participants: 2,
  Rewards: 3,
  Games: 4,
  Rankings: 5,
};

export const YesNo = {
  false: 'No',
  true: 'Yes',
};

export const SortOrder = {
  0: 'Ascending',
  1: 'Descending',
};

export const Frequency = {
  1: '1 time',
  2: '2 times',
  3: '3 times',
  5: '5 times',
  10: '10 times',
};

export const Regularity = {
  1: 'everyday',
  2: 'every 2 days',
  3: 'every 3 days',
  4: 'every 4 days',
  5: 'every 5 days',
  6: 'every 6 days',
  7: 'every 7 days',
  8: 'every 8 days',
  9: 'every 9 days',
  10: 'every 10 days',
  14: 'every 14 days',
  20: 'every 20 days',
  30: 'every 30 days',
};

export const PeriodUnit = {
  'd': 'Day',
  'w': 'Week',
  'm': 'Month',
  'y': 'Year',
  Day: 'd',
  Week: 'w',
  Month: 'm',
  Year: 'y'
};

export const Period = {
  100: 'daily (00:00-24:00)',
  101: '1 day',
  102: '2 days',
  103: '3 days',
  104: '4 days',
  105: '5 days',
  106: '6 days',
  107: '7 days',
  108: '8 days',
  109: '9 days',
  110: '10 days',
  114: '14 days',
  120: '20 days',
  130: '30 days',
  200: 'weekly (Mon.-Sun.)',
  201: '1 week',
  202: '2 weeks',
  203: '3 weeks',
  204: '4 weeks',
  205: '5 weeks',
  206: '6 weeks',
  300: 'monthly (1th-31th)',
  301: '1 month',
  302: '2 months',
  303: '3 months',
  306: '6 months',
  400: 'yearly (1th-365th)',
  401: '1 year',
};

export const AttributePeriod = {
  301: '1 month',
  302: '2 months',
  303: '3 months',
  306: '6 months',
  401: '1 year',
};

export const DashboardTileType = {
  Total: 1,
  Chart: 2,
  Top: 3,
};

export const ChartMeasure = {
  ActionsCreated: 1,
  PlayersCreated: 2,
  GamesPublished: 3,
  GameRoundsCompleted: 4,
  ExperienceEarned: 5,
  CurrencyEarned: 6,
  LeaderboardsPublished: 7,
  1: 'Actions Created',
  2: 'Players Created',
  3: 'Games Published',
  4: 'Games Completed',
  5: 'Experience Earned',
  6: 'Currency Earned',
  7: 'Leaderboards Published',
};

export const ChartPeriod = {
  Daily: 1,
  Weekly: 2,
  Monthly: 3,
  1: 'Daily',
  2: 'Weekly',
  3: 'Monthly',
};

export const ChartType = {
  Area: 1,
  Bar: 2,
  Line: 3,
  Pie: 4,
};

export const TopMeasure = {
  GameRoundsCompletedByPlayer: 1,
  RoundsCompletedByGame: 2,
  ExperienceEarnedByPlayer: 3,
  CurrencyEarnedByPlayer: 4,
  1: 'Top Players by Games Completed',
  2: 'Top Games Completed',
  3: 'Top Players by Experience',
  4: 'Top Players by Currency',
};

export const TopPeriod = {
  AllTIme: 1,
  Today: 2,
  Yesterday: 3,
  CurrentWeek: 4,
  PreviousWeek: 5,
  CurrentMonth: 6,
  PreviousMonth: 7,
  1: 'All Time',
  2: 'Today',
  3: 'Yesterday',
  4: 'This Week',
  5: 'Previous Week',
  6: 'This Month',
  7: 'Previous Month',
};

export const NodeClass = {
  Journey: 1,
  Milestone: 2,
  1: 'Journey',
  2: 'Milestone',
};

export const NotificationType = {
  GameStarted: 11,
  GameEnded: 12,

  RoundStarted: 1,
  RoundProgress: 3,
  RoundSucceeded: 2,
  RoundCompleted: 2,  // TODO: replace RoundCompleted with RoundSucceeded everywhere (may affect API)
  RoundFailed: 24,
  RoundCancelled: 25,

  MilestoneStarted: 31,
  MilestoneProgress: 32,
  MilestoneSucceeded: 33,
  MilestoneFailed: 34,
  MilestoneCancelled: 35,

  External: 4,

  11: 'Game Started',
  12: 'Game Ended',

  1: 'Round Started',
  3: 'Round Progress',
  2: 'Round Completed',  // TODO: replace RoundCompleted with RoundSucceeded everywhere (may affect API)
  24: 'Round Failed',
  25: 'Round Cancelled',

  31: 'Milestone Started',
  32: 'Milestone Progress',
  33: 'Milestone Succeeded',
  34: 'Milestone Failed',
  35: 'Milestone Cancelled',
};

export const IncentiveStage = {
  GameStarted: 11,
  GameEnded: 12,

  RoundStarted: 21,
  RoundProgress: 22,
  RoundSucceeded: 23,
  RoundFailed: 24,
  RoundCancelled: 25,

  MilestoneStarted: 31,
  MilestoneProgress: 32,
  MilestoneSucceeded: 33,
  MilestoneFailed: 34,
  MilestoneCancelled: 35,

  11: 'Game Started',
  12: 'Game Ended',

  21: 'Round Started',
  22: 'Round Progress',
  23: 'Round Succeeded',
  24: 'Round Failed',
  25: 'Round Cancelled',

  31: 'Milestone Started',
  32: 'Milestone Progress',
  33: 'Milestone Succeeded',
  34: 'Milestone Failed',
  35: 'Milestone Cancelled'
};

export const NotificationPromptType = {
  Copywrite: 1,
  Personalize: 2,
  1: 'Copywrite',
  2: 'Personalize',
};

export const SettingType = {
  PageSize: 1,
  PreferTable: 2,
  UseBlockchain: 3,
  GenerateNotificationRoundStarted: 4,
  GenerateNotificationRoundCompleted: 5,
  EnableMessageCustomization: 7,
  MessageLanguage: 8,
  1: 'Page Size',
  2: 'Prefer Table',
  3: 'Use Blockchain',
  4: 'Generate Notifications on Round Start',
  5: 'Generate Notifications on Round Complete',
  7: 'Enable Message Customization',
  8: 'Message Language',
};

export const Languages = {
  1: 'English',
  2: 'French',
  3: 'Portuguese',
  4: 'Spanish',
};

export const SettingTypeEnums = {
  8: Languages,
};

export const SearchType = {
  1: 'Game',
  2: 'Rule',
  3: 'Filter',
  4: 'Participant',
  5: 'Reward',
  6: 'ActionType',
  7: 'Action',
  8: 'User',
  9: 'Player',
  10: 'Group',
  11: 'Player Attribute',
  12: 'Level',
  13: 'Leaderboard',
  14: 'Game Attribute',
  15: 'Ranking',
  Game: 1,
  Rule: 2,
  Filter: 3,
  Participant: 4,
  Reward: 5,
  ActionType: 6,
  Action: 7,
  User: 8,
  Player: 9,
  Group: 10,
  PlayerAttribute: 11,
  Level: 12,
  Leaderboard: 13,
  GameAttribute: 14,
  Ranking: 15,
};

export const PlayerSource = {
  1: 'Customer',
  2: 'Policy',
  3: 'Vehicle',
  4: 'Unit',
  5: 'Driver',
  Customer: 1,
  Policy: 2,
  Vehicle: 3,
  Unit: 4,
  Driver: 5,
};

export const Delay = {
  6: '6 hours',
  12: '12 hours',
  18: '18 hours',
  24: '24 hours',
  30: '30 hours',
  36: '36 hours',
  42: '42 hours',
  48: '48 hours',
  54: '54 hours',
  60: '60 hours',
  66: '66 hours',
  72: '72 hours',
};

export const BlockchainStatus = {
  1: 'New',
  2: 'Successful',
  3: 'Configuration Issue',
  4: 'Insufficient Balance',
  5: 'Communication Failure',
  6: 'Unknown Error',
  New: 1,
  Successful: 2,
  ConfigurationFailure: 3,
  InsufficientBalanceFailure: 4,
  CommunicationFailure: 5,
  UnknownFailure: 6,
};

export const NotificationHours = {
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
  24: '12:00 AM',
};

export const NotificationPeriod = {
  101: 'Every day',
  102: 'Every 2 days',
  103: 'Every 3 days',
  104: 'Every 4 days',
  105: 'Every 5 days',
  106: 'Every 6 days',
  107: 'Every 7 days',
  108: 'Every 8 days',
  109: 'Every 9 days',
  110: 'Every 10 days',
  114: 'Every 14 days',
  120: 'Every 20 days',
  130: 'Every 30 days',
  201: 'Every week',
  202: 'Every 2 weeks',
  203: 'Every 3 weeks',
  204: 'Every 4 weeks',
  205: 'Every 5 weeks',
  206: 'Every 6 weeks',
  301: 'Every month',
  302: 'Every 2 months',
  303: 'Every 3 months',
  306: 'Every 6 months',
};

export const ConvertToOptions = enumType => Object.keys(enumType)
  .filter(key => !Number.isNaN(Number(key)) || key === 'true' || key === 'false')
  .map(key => {
    let value = key;
    if (!Number.isNaN(key)) {
      value = parseInt(key, 10);
    }
    if (key === 'true') value = true;
    if (key === 'false') value = false;
    return { value, label: enumType[key] };
  });

export const FrequencyRegularity = () => {
  const frequencies = ConvertToOptions(Frequency);
  const regularities = ConvertToOptions(Regularity);

  const result = [];

  frequencies.forEach(freq => {
    result.push({
      label: `${freq.label}`,
      value: freq.value * 1000,
      frequency: freq.value,
    });
    regularities.forEach(reg => {
      result.push({
        label: `${freq.label} ${reg.label}`,
        value: freq.value * 1000 + reg.value,
        regularity: reg.value,
        frequency: freq.value,
      });
    });
  });

  return result;
};
