import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import * as dayjs from 'dayjs';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';

import OutcomeView from '../controls/OutcomeView';

const JourneyStartNodeView = ({ value, outcomeTypes }) => {
    const { name, description, timeframe, message, incentives } = value;

    const startOn = timeframe.value.start;
    const endOn = timeframe.value.end;

    const format = "MMM D";
    let timeframeDescription = "";

    if (startOn !== null && endOn !== null) {
        timeframeDescription = (
            <>
                From {dayjs(startOn).format(format)} until {dayjs(endOn).format(format)}
            </>
        );
    }
    else if (startOn !== null && endOn === null) {
        timeframeDescription = (
            <>
                From {dayjs(startOn).format(format)}
            </>
        );
    }
    else if (startOn === null && endOn !== null) {
        timeframeDescription = (
            <>
                Until {dayjs(endOn).format(format)}
            </>
        );
    }
    else {
        timeframeDescription = (
            <>
                Always active
            </>
        );
    }

    return (
        <>
            <List dense disablePadding>
                <ListItem disablePadding disableGutters>
                    <ListItemIcon><DescriptionOutlinedIcon/></ListItemIcon>
                    <ListItemText primary={name} secondary={description}></ListItemText>
                </ListItem>
                <ListItem disablePadding disableGutters>
                    <ListItemIcon><ScheduleIcon/></ListItemIcon>
                    <ListItemText>{timeframeDescription}</ListItemText>
                </ListItem>
            </List>

            <OutcomeView types={outcomeTypes} messages={{ journeyStart: message }} incentives={{ journeyStart: incentives }} />
        </>
    );
};

export default JourneyStartNodeView;
