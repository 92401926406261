import React, { Component } from 'react';
import axios from 'axios';
import Loading from '../images/loading.gif';

export default class Background extends Component {
  loadingQueue = 0;

  loadingIndicatorRef = React.createRef();

  componentDidMount() {
    axios.interceptors.request.use(
      config => {
        this.triggerLoadingIndicator(+1);
        return config;
      },
      err => {
        this.triggerLoadingIndicator(+1);
        return Promise.reject(err);
      },
    );

    axios.interceptors.response.use(
      response => {
        this.triggerLoadingIndicator(-1);
        return response;
      },
      err => {
        this.triggerLoadingIndicator(-1);
        return Promise.reject(err);
      },
    );
  }

  shouldComponentUpdate() {
    return false;
  }

  triggerLoadingIndicator = index => {
    this.loadingQueue += index;
    if (this.loadingQueue > 0) {
      this.loadingIndicatorRef.current.style.display = 'inherit';
    } else {
      this.loadingIndicatorRef.current.style.display = 'none';
    }
  };

  spawnTriangle = size => {
    const h = window.innerHeight - 50;
    const w = window.innerWidth - 50;

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    return (
      <div className={`${size} rotating-${size}`}
        style={{
          top: getRandomInt(100, h - 100),
          left: getRandomInt(100, w - 100),
        }} />
    );
  };

  render() {
    return (
      <>
        {/*
        {this.spawnTriangle('a')}
        {this.spawnTriangle('a')}
        {this.spawnTriangle('a')}
        {this.spawnTriangle('b')}
        {this.spawnTriangle('b')}
        {this.spawnTriangle('c')}
        {this.spawnTriangle('c')}
        {this.spawnTriangle('c')}
        {this.spawnTriangle('a')}
        {this.spawnTriangle('a')}
        {this.spawnTriangle('a')}
        {this.spawnTriangle('b')}
        {this.spawnTriangle('b')}
        {this.spawnTriangle('c')}
        {this.spawnTriangle('c')}
        {this.spawnTriangle('c')}
        */}
        <div id="busy-indicator" ref={this.loadingIndicatorRef}>
          <img src={Loading} alt="loading" />
        </div>
      </>
    );
  }
}
