
export const theme = {
    nodes: {
        body: {
            regular: {
                backgroundColor: "whitesmoke",
                borderColor: "white", borderStyle: 'solid', borderWidth: '2px', borderRadius: '5px'
            },
            container: {
                backgroundColor: "transparent",
                borderColor: "#aaa", borderStyle: 'dotted', borderWidth: '4px'
            }
        },
        controls: {
            backgroundColor: "white",
            borderColor: "gray",
        },
    },
    edges: {
        stroke: "white",
        strokeWidth: 2,
    }
};
