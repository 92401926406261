import React, { useContext, useState } from 'react';
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import EditorBase from '../EditorBase';
import JourneyContext from '../JourneyContext';

const TargetEditor = ({ value, onChange }) => {
    const { id, name } = value || {};

    const { allRules, allRankings, isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    let allTargets = [];

    if (isGameMode) {
        allTargets = allRules;
    }
    else if (isLeaderboardMode) {
        allTargets = allRankings;
    }

    const handleOnChange = (event) => {
        const selectedId = event.target.value;
        const selectedTarget = allTargets.find(t => t.id === selectedId);
        onChange(selectedTarget);
    };

    return (
        <EditorBase title="Target" summary={name}>
            <FormControl fullWidth>
                <Select value={id || ""} onChange={handleOnChange}>
                    {
                        allTargets.map((t) => (<MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>))
                    }
                </Select>
            </FormControl>
        </EditorBase>
    );
};

export default TargetEditor;
