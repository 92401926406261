export class ExternalTreeBuilder {
    constructor(game) {
        this.game = game;
        this.participantId = null;
    }

    selectParticipant = (participantId) => {
        this.participantId = participantId;
    }


    #CONJUNCTION_AND = 1;
    #CONJUNCTION_OR = 2;
    #CONJUNCTION_THEN = 3;


    #getChildrenConjunction = (ruleUnion) => {
        const isTopLevel = (ruleUnion === this.game);

        if (isTopLevel) {
            const childRuleUnions = ruleUnion.ruleUnions.filter((ru) => ru.participantId === this.participantId);

            if (childRuleUnions.length > 0)
                return childRuleUnions[0].conjunction;
            else
                return this.#CONJUNCTION_THEN;
        }
        else {
            return ruleUnion.childrenConjunction;
        }
    };


    #isAndConjunction = (typeId) => (typeId === this.#CONJUNCTION_AND);
    #isOrConjunction = (typeId) => (typeId === this.#CONJUNCTION_OR);
    #isThenConjunction = (typeId) => (typeId === this.#CONJUNCTION_THEN);

    #isSequentialRuleUnion = (ruleUnion) => this.#isThenConjunction(this.#getChildrenConjunction(ruleUnion));
    #belongsToSequentialRuleUnion = (ruleUnion) => this.#isThenConjunction(ruleUnion.conjunction);


    /**
     * Finds the closest parent object in the hierarchy.
     * For a "rule" that is expected to be the closest "ruleUnion" object.
     * For a "ruleUnion" that is expected to be the closest "ruleUnion" object or "game".
     */
    #findParentObject = (root, child, parentObject = root) => {
        for (const key in root) {
            if ((typeof root[key] === 'object') && (root[key] !== null)) {
                if (root[key] === child) {
                    return parentObject;
                }

                const newParentObject = Array.isArray(root[key]) ? parentObject : root[key];

                const parent = this.#findParentObject(root[key], child, newParentObject);

                if (parent) {
                    return parent;
                }
            }
        }

        return null;
    };


    #newId = () => {
        const int32 = 2147483648;
        return Math.floor(Date.now() * Math.random()) % int32;
    };


    #createEmptyRule = () => {
        return {
            "unionEntityId": this.#newId(),  // Id of the UnionRule entity connecting the Rule with its containing RuleUnion
            "id": null,  // Id of the Rule entity
            "name": null,
            "imageId": null,
            "periodValue": null,
            "periodUnit": null
        };
    };


    #createEmptyRuleUnion = (peerConjunction, childrenConjunction) => {
        return {
            "unionEntityId": this.#newId(),  // Id of the Path entity connecting the RuleUnion with its parent RuleUnion
            "failure": null,
            "id": this.#newId(),  // Id of the RuleUnion entity
            "participantId": this.participantId,
            "conjunction": peerConjunction,
            "childrenConjunction": childrenConjunction,
            "rules": [],
            "ruleUnions": [],
            "games": []
        };
    };


    #createEmptySequentialRuleUnion = (peerConjunction) => {
        return this.#createEmptyRuleUnion(peerConjunction, this.#CONJUNCTION_THEN);
    };

    #createEmptyParallelRuleUnion = (peerConjunction) => {
        return this.#createEmptyRuleUnion(peerConjunction, this.#CONJUNCTION_AND);  // Using AND as the default children conjunction
    };


    #appendEmptyRule = (parentRuleUnion) => {
        const newRule = this.#createEmptyRule();
        parentRuleUnion.rules.push(newRule);
        return newRule;
    };

    #insertEmptyRule = (parentRuleUnion, afterRule) => {
        const index = parentRuleUnion.rules.indexOf(afterRule);
        const newRule = this.#createEmptyRule();
        parentRuleUnion.rules.splice(index + 1, 0, newRule);
        return newRule;
    };


    #appendEmptySequentialRuleUnion = (parentRuleUnion) => {
        const newRuleUnion = this.#createEmptySequentialRuleUnion(this.#getChildrenConjunction(parentRuleUnion));
        parentRuleUnion.ruleUnions.push(newRuleUnion);
        return newRuleUnion;
    };

    #insertEmptySequentialRuleUnion = (parentRuleUnion, afterRuleUnion) => {
        const index = parentRuleUnion.ruleUnions.indexOf(afterRuleUnion);
        const newRuleUnion = this.#createEmptySequentialRuleUnion(this.#getChildrenConjunction(parentRuleUnion));
        parentRuleUnion.ruleUnions.splice(index + 1, 0, newRuleUnion);
        return newRuleUnion;
    };


    #appendEmptyParallelRuleUnion = (parentRuleUnion) => {
        const newRuleUnion = this.#createEmptyParallelRuleUnion(this.#getChildrenConjunction(parentRuleUnion));
        parentRuleUnion.ruleUnions.push(newRuleUnion);
        return newRuleUnion;
    };

    #insertEmptyParallelRuleUnion = (parentRuleUnion, afterRuleUnion) => {
        const index = parentRuleUnion.ruleUnions.indexOf(afterRuleUnion);
        const newRuleUnion = this.#createEmptyParallelRuleUnion(this.#getChildrenConjunction(parentRuleUnion));
        parentRuleUnion.ruleUnions.splice(index + 1, 0, newRuleUnion);
        return newRuleUnion;
    };


    /*
    NOTES:

    ruleUnion can consist either of a list of rules or a list of ruleUnions.
    This is important because ruleUnions children are ordered.

    baseUnion is either a game or a ruleUnion.
    ruleUnion has "conjunction" (aka peerConjunction) and "childrenConjunction", while game doesn't have these fields.
    game is considered a sequential ruleUnion by default, that has ruleUnions, but doesn't have rules.

    parentRuleUnion.childrenConjunction = childRuleUnion.conjunction
    */


    #moveRuleToAnotherRuleUnion = (rule, sourceRuleUnion, targetRuleUnion) => {
        sourceRuleUnion.rules = sourceRuleUnion.rules.filter(r => r !== rule);
        targetRuleUnion.rules.push(rule);
    };

    #moveRuleUnionToAnotherRuleUnion = (ruleUnion, sourceRuleUnion, targetRuleUnion) => {
        sourceRuleUnion.ruleUnions = sourceRuleUnion.ruleUnions.filter(ru => ru !== ruleUnion);
        targetRuleUnion.ruleUnions.push(ruleUnion);

        // Update the peer conjunction of the ruleUnion to match the children conjunction of the target ruleUnion
        ruleUnion.conjunction = targetRuleUnion.childrenConjunction;
    };


    /**
     * Wraps the specified rule into a sequential ruleUnion, belonging to the same parent ruleUnion.
     * The new sequential ruleUnion is added as a child to the rule's parent ruleUnion, and the rule is removed from the list of its parent's rules.
     */
    #wrapRuleIntoSequentialRuleUnion = (rule, parentRuleUnion) => {
        const containerRuleUnion = this.#createEmptySequentialRuleUnion(parentRuleUnion.childrenConjunction);
        parentRuleUnion.ruleUnions.push(containerRuleUnion);

        this.#moveRuleToAnotherRuleUnion(rule, parentRuleUnion, containerRuleUnion);

        return containerRuleUnion;
    };

    /**
     * Wraps the specified ruleUnion into a sequential ruleUnion, belonging to the same parent ruleUnion.
     * The new sequential ruleUnion replaces the original ruleUnion in the list of its parent's ruleUnions.
     */
    #wrapRuleUnionIntoSequentialRuleUnion = (ruleUnion, parentRuleUnion) => {
        const containerRuleUnion = this.#createEmptySequentialRuleUnion(ruleUnion.conjunction);

        // Keep the container ruleUnion at the same position as the original ruleUnion
        const index = parentRuleUnion.ruleUnions.indexOf(ruleUnion);
        parentRuleUnion.ruleUnions.splice(index, 0, containerRuleUnion);

        this.#moveRuleUnionToAnotherRuleUnion(ruleUnion, parentRuleUnion, containerRuleUnion);

        return containerRuleUnion;
    };


    #wrapEveryRuleIntoSequentialRuleUnion = (ruleUnion) => {
        ruleUnion.rules.forEach(rule => {
            this.#wrapRuleIntoSequentialRuleUnion(rule, ruleUnion);
        });
    };


    addParticipant = (targetParticipant, sourceParticipant) => {
        this.game.gameParticipants.push({
            id: this.#newId(),
            participant: targetParticipant,
            attributes: {}
        });

        if (sourceParticipant !== undefined) {
            const sourceParticipantId = sourceParticipant ? sourceParticipant.id : null;

            const mappedIds = new Map();

            const createMappedId = (id) => {
                if (!mappedIds.has(id)) {
                    mappedIds.set(id, this.#newId());
                }

                return mappedIds.get(id);
            };

            const getMappedId = (id) => {
                return mappedIds.get(id);
            };

            const duplicateRuleUnions = (root) => {
                return root.ruleUnions.filter((ru) => ru.participantId === sourceParticipantId).map((ru) => {
                    return {
                        ...ru,
                        unionEntityId: ru.unionEntityId ? this.#newId() : undefined,
                        id: this.#newId(),
                        participantId: targetParticipant.id,
                        rules: ru.rules.map((rule) => {
                            return {
                                ...rule,
                                unionEntityId: createMappedId(rule.unionEntityId)
                            };
                        }),
                        ruleUnions: duplicateRuleUnions(ru)
                    };
                });
            };

            const duplicatedRuleUnions = duplicateRuleUnions(this.game);

            const duplicatedGameNotifications = this.game.gameNotifications.filter((n) => n.participantId === sourceParticipantId).map((n) => {
                return {
                    ...n,
                    id: this.#newId(),
                    participantId: targetParticipant.id,
                    unionRuleId: getMappedId(n.unionRuleId)
                };
            });

            const duplicatedRewardUnions = this.game.rewardUnions.filter((ru) => ru.participantId === sourceParticipantId).map((ru) => {
                return {
                    ...ru,  // TODO: deep copy?
                    id: this.#newId(),
                    participantId: targetParticipant.id,
                    unionRuleId: getMappedId(ru.unionRuleId)
                };
            });

            this.game.ruleUnions.push(...duplicatedRuleUnions);
            this.game.gameNotifications.push(...duplicatedGameNotifications);
            this.game.rewardUnions.push(...duplicatedRewardUnions);
        }
        else {
            const newRuleUnion = this.#createEmptySequentialRuleUnion(this.#CONJUNCTION_THEN);
            newRuleUnion.participantId = targetParticipant.id;

            this.game.ruleUnions.push(newRuleUnion);

            this.game.rewardUnions.push({
                id: this.#newId(),
                participantId: targetParticipant.id,
                unionRuleId: null,
                stage: null,
                experience: null,
                currency: null,
                rewards: []
            });
        }
    };


    removeParticipant = (participant) => {
        this.game.gameParticipants = this.game.gameParticipants.filter((p) => p.participant.id !== participant.id);
        this.game.ruleUnions = this.game.ruleUnions.filter((r) => r.participantId !== participant.id);
        this.game.gameNotifications = this.game.gameNotifications.filter((n) => n.participantId !== participant.id);
        this.game.rewardUnions = this.game.rewardUnions.filter((r) => r.participantId !== participant.id);
    };


    root_initWithParallelRuleUnion = () => {
        // TODO:
        // What about multiple ruleUnions at root level?
        // Game as top=level union can be either sequential or parallel.

        // Remove all existing ruleUnions for this participant
        this.game.ruleUnions = this.game.ruleUnions.filter((u) => u.participantId !== this.participantId);

        const newRuleUnion = this.#appendEmptyParallelRuleUnion(this.game);

        return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
    };


    root_initWithRule = () => {
        // Remove all existing ruleUnions for this participant
        this.game.ruleUnions = this.game.ruleUnions.filter((u) => u.participantId !== this.participantId);

        // Create a top-level sequential container with a rule.
        // The top-level peer conjunction is set to THEN by default.
        const newRuleUnion = this.#appendEmptySequentialRuleUnion(this.game);
        const newRule = this.#appendEmptyRule(newRuleUnion);

        return { rule: newRule };
    };


    /**
     * Appends a new rule to the specified ruleUnion.
     * The rule is added either to the list of rules or to the list of ruleUnions, depending on what is used in this ruleUnion.
     */
    parallelRuleUnion_appendRule = (baseUnion) => {
        // TODO: better Immer for deep update

        if (baseUnion.ruleUnions.length === 0) {
            // The ruleUnion consists of rules.
            // Add a new rule to the list of rules.

            const newRule = this.#appendEmptyRule(baseUnion);

            return { rule: newRule };
        }
        else {
            // The ruleUnion consists of ruleUnions.
            // Add a new sequential ruleUnion containing the new rule.

            const newRuleUnion = this.#appendEmptySequentialRuleUnion(baseUnion);
            const newRule = this.#appendEmptyRule(newRuleUnion);

            return { rule: newRule };
        }
    };


    parallelRuleUnion_appendParallelRuleUnion = (baseUnion) => {
        // Appending a ruleUnion means that all existing rules (if any) must be converted to ruleUnions
        this.#wrapEveryRuleIntoSequentialRuleUnion(baseUnion);

        const newRuleUnion = this.#appendEmptyParallelRuleUnion(baseUnion);

        return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
    };


    /**
     * Adds a new rule that follows the specified rule.
     */
    rule_addNextRule = (rule, failure = null) => {  // TODO: failure
        const parentRuleUnion = this.#findParentObject(this.game, rule);

        // TODO: better use Immer for deep updates

        if (this.#isSequentialRuleUnion(parentRuleUnion)) {
            // Current rule is part of a sequential ruleUnion ("THEN").
            // The current rule may be anywhere inside the ruleUnion.
            // Add a new rule to this ruleUnion, right after the current rule.

            const newRule = this.#insertEmptyRule(parentRuleUnion, rule);

            return { rule: newRule };
        }
        else {
            // Current rule is part of a parallel ruleUnion ("OR" or "AND").
            // Convert all rules of this ruleUnion to sequential ruleUnions.
            // Then add a new rule next to the current rule.

            // Convert all rules (there is at least one) to sequential ruleUnions
            this.#wrapEveryRuleIntoSequentialRuleUnion(parentRuleUnion);

            // Refresh the parent ruleUnion, because it is now one level deeper
            const newParentRuleUnion = this.#findParentObject(parentRuleUnion, rule);

            const newRule = this.#appendEmptyRule(newParentRuleUnion);

            return { rule: newRule };
        }
    };


    rule_addNextParallelRuleUnion = (rule, failure = null) => {  // TODO: failure
        const parentRuleUnion = this.#findParentObject(this.game, rule);

        if (this.#isSequentialRuleUnion(parentRuleUnion)) {
            // Current rule is part of a sequential ruleUnion ("THEN").
            // Convert all rules of this ruleUnion to sequential ruleUnions.
            // Then add a new ruleUnion next to the ruleUnion containing the current rule.

            // Convert all rules (there is at least one) to sequential ruleUnions
            this.#wrapEveryRuleIntoSequentialRuleUnion(parentRuleUnion);

            // Refresh the parent ruleUnion, because it is now one level deeper
            const newParentRuleUnion = this.#findParentObject(parentRuleUnion, rule);

            const newRuleUnion = this.#insertEmptyParallelRuleUnion(parentRuleUnion, newParentRuleUnion);

            return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
        }
        else {
            // Current rule is part of a parallel ruleUnion ("OR" or "AND").
            // Convert all rules of this ruleUnion to sequential ruleUnions.
            // Replace (again) the current rule with a sequential ruleUnion.
            // Then add a new ruleUnion next to it.

            // Convert all rules (there is at least one) to sequential ruleUnions
            this.#wrapEveryRuleIntoSequentialRuleUnion(parentRuleUnion);

            // Refresh the parent ruleUnion, because it is now one level deeper
            const newParentRuleUnion = this.#findParentObject(parentRuleUnion, rule);

            // Move the current rule one level deeper again.
            this.#wrapRuleIntoSequentialRuleUnion(rule, newParentRuleUnion);

            const newRuleUnion = this.#appendEmptyParallelRuleUnion(newParentRuleUnion);

            return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
        }
    };


    parallelRuleUnion_addNextRule = (baseUnion, failure = null) => {  // TODO: failure
        const parentRuleUnion = this.#findParentObject(this.game, baseUnion);

        if (this.#belongsToSequentialRuleUnion(baseUnion)) {
            // Current ruleUnion is part of a sequential ruleUnion ("THEN").
            // The current ruleUnion may be anywhere inside its parent ruleUnion.
            // Add a new rule contained in a sequential ruleUnion, right after the current ruleUnion.

            const newRuleUnion = this.#insertEmptySequentialRuleUnion(parentRuleUnion, baseUnion);
            const newRule = this.#appendEmptyRule(newRuleUnion);

            return { rule: newRule };
        }
        else {
            // Current ruleUnion is part of a parallel ruleUnion ("OR" or "AND").
            // Wrap the current ruleUnion into a sequential ruleUnion.
            // Then add a new rule contained in a sequential ruleUnion, right after the current ruleUnion.

            const newParentRuleUnion = this.#wrapRuleUnionIntoSequentialRuleUnion(baseUnion, parentRuleUnion);

            const newRuleUnion = this.#appendEmptySequentialRuleUnion(newParentRuleUnion);
            const newRule = this.#appendEmptyRule(newRuleUnion);

            return { rule: newRule };
        }
    };


    parallelRuleUnion_addNextParallelRuleUnion = (baseUnion, failure = null) => {  // TODO: failure
        const parentRuleUnion = this.#findParentObject(this.game, baseUnion);

        if (this.#belongsToSequentialRuleUnion(baseUnion)) {
            // Current ruleUnion is part of a sequential ruleUnion ("THEN").
            // The current ruleUnion may be anywhere inside its parent ruleUnion.
            // Add a new ruleUnion to this ruleUnion, right after the current ruleUnion.

            const newRuleUnion = this.#insertEmptyParallelRuleUnion(parentRuleUnion, baseUnion);

            return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
        }
        else {
            // Current ruleUnion is part of a parallel ruleUnion ("OR" or "AND").
            // Wrap the current ruleUnion into a sequential ruleUnion.
            // Then add a new ruleUnion next to it.

            const newParentRuleUnion = this.#wrapRuleUnionIntoSequentialRuleUnion(baseUnion, parentRuleUnion);

            const newRuleUnion = this.#appendEmptyParallelRuleUnion(newParentRuleUnion);

            return { baseUnion: newRuleUnion, ruleUnions: undefined, rules: [], childrenConjunction: newRuleUnion.childrenConjunction };
        }
    };


    #isEmpty = (ruleUnion) => {
        return (ruleUnion.rules.length === 0) && (ruleUnion.ruleUnions.length === 0);
    };


    #isBranch = (ruleUnion) => {
        return (ruleUnion.failure !== undefined) && (ruleUnion.failure !== null);
    };


    #pruneAfter = (ruleUnion) => {
        if (ruleUnion === this.game) return;

        const parentRuleUnion = this.#findParentObject(this.game, ruleUnion);
        const index = parentRuleUnion.ruleUnions.indexOf(ruleUnion);

        // To prune ruleUnions following the specified ruleUnion:
        // - the specified ruleUnion must be part of a sequential ruleUnion
        // - the specified ruleUnion must not be the start of a branch

        if (this.#isSequentialRuleUnion(parentRuleUnion) && !this.#isBranch(ruleUnion)) {
            if (this.#isEmpty(ruleUnion)) {
                // Prune beginning from the specified ruleUnion if it is empty
                parentRuleUnion.ruleUnions = parentRuleUnion.ruleUnions.filter((ru, i) => (i < index) || (ru.participantId !== this.participantId));
            }
            else {
                // Prune beginning from the next ruleUnion if the specified ruleUnion is not empty
                parentRuleUnion.ruleUnions = parentRuleUnion.ruleUnions.filter((ru, i) => (i <= index) || (ru.participantId !== this.participantId));
            }

            this.#pruneAfter(parentRuleUnion);
        }
        else {
            if (this.#isEmpty(ruleUnion)) {
                // Remove the ruleUnion if it is empty
                parentRuleUnion.ruleUnions.splice(index, 1);
            }
        }
    };


    /**
     * Remove the specified rule, as well as everything that follows it.
     */
    rule_prune = (rule) => {
        const parentRuleUnion = this.#findParentObject(this.game, rule);

        if (this.#isSequentialRuleUnion(parentRuleUnion)) {
            // The rule is part of a sequential ruleUnion consisting of rules.
            // Remove all rules starting from the specified rule.

            const index = parentRuleUnion.rules.indexOf(rule);
            parentRuleUnion.rules.splice(index);

            this.#pruneAfter(parentRuleUnion);
        }
        else {
            // The rule is part of a parallel ruleUnion consisting of rules.
            // Simply remove the rule from the list.

            const index = parentRuleUnion.rules.indexOf(rule);
            parentRuleUnion.rules.splice(index, 1);
        }
    };


    /**
     * Remove the specified ruleUnion, together with all its contents, as well as everything that follows it.
     */
    parallelRuleUnion_prune = (baseUnion) => {
        baseUnion.rules = [];
        baseUnion.ruleUnions = baseUnion.ruleUnions.filter((ru) => ru.participantId !== this.participantId);

        this.#pruneAfter(baseUnion);
    };


    ruleUnion_setChildrenConjunction = (baseUnion, childrenConjunction) => {
        if (baseUnion !== this.game) {
            // A ruleUnion has "childrenConjunction" field, while a game doesn't have it
            baseUnion.childrenConjunction = childrenConjunction;
        }

        baseUnion.ruleUnions.forEach(ruleUnion => {
            ruleUnion.conjunction = childrenConjunction;
        });

        return { baseUnion };
    };
}
