import React, { useContext } from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Stack, StepContent, StepIcon, Typography } from '@mui/material';
import JourneyContext from '../JourneyContext';
import OutcomeView from '../controls/OutcomeView';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import ScheduleIcon from '@mui/icons-material/Schedule';

const MilestoneNodeView = ({ value, outcomeTypes }) => {
    const { target, timeframe, messages, incentives } = value;

    const { type: timeframeType, intervalValue, intervalUnit, datetimeValue } = timeframe;

    const { isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    const humanizeTarget = () => {
        if (!target) {
            return "None";
        }

        return target.name;
    };

    const humanizeTimeframe = () => {
        switch (timeframeType) {
        case "none":
            return "Complete anytime";

        case "interval":
            switch (intervalUnit) {
                case "hours":
                    return intervalValue + " hour(s)";
                case "days":
                    return intervalValue + " day(s)";
                case "weeks":
                    return intervalValue + " week(s)";
                case "months":
                    return intervalValue + " month(s)";
            }
            break;

        case "datetime":
            return datetimeValue.format("DD-MM-YYYY HH:mm");
        }
    };

    return (
        <>
            <List dense disablePadding>
                <ListItem disableGutters disablePadding>
                    <ListItemIcon><MyLocationIcon/></ListItemIcon>
                    <ListItemText>{humanizeTarget()}</ListItemText>
                </ListItem>
                <ListItem disableGutters disablePadding>
                    <ListItemIcon><ScheduleIcon/></ListItemIcon>
                    <ListItemText>{humanizeTimeframe()}</ListItemText>
                </ListItem>
            </List>

            <OutcomeView types={outcomeTypes} messages={messages} incentives={incentives} />
{/*
            <Stack direction="row" spacing={0.5} useFlexGap flexWrap="wrap">
                {isGameMode && (
                    <OutcomeDescription types={outcomeTypes} messages={messages} incentives={incentives} />
                )}
            </Stack>
*/}
        </>
    );
};

export default MilestoneNodeView;
