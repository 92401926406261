import { v4 as uuid } from 'uuid';
import {
    convertToInternalValue_JourneyEndNode,
    convertToInternalValue_JourneyStartNode,
    convertToInternalValue_MilestoneNode,
    convertToInternalValue_RoundEndNode,
    convertToInternalValue_RoundStartNode
} from './convert';
import { theme } from './theme';

export class InternalTreeBuilder {
    constructor(game) {
        this.game = game;
        this.participantId = null;
    };

    selectParticipant = (participantId) => {
        this.participantId = participantId;
    };


    buildRootNodes = () => {
        const nodes = [
            this.create_JourneyStartNode(),
            this.create_RoundStartNode(),
            this.create_JourneyNode(),
            this.create_RoundEndNode(),
            this.create_JourneyEndNode()
        ];

        const edges = this.connectNodes(nodes);

        return [nodes, edges];
    };


    buildJourneyNode = () => {
        const nodes = this.#gameToNodes();
        const edges = this.connectNodes(nodes);

        return { nodes, edges };
    };


    buildGroupNode = (rules, ruleUnions) => {
        const subgroups = [];

        if (rules) {
            rules.forEach(rule => {
                const nodeBuilderParams = {
                    rule: rule
                };

                const nodes = [this.create_MilestoneNode(nodeBuilderParams, 'success')];  // Default reason for a sequence of rules is "success"
                const edges = [];

                subgroups.push({ nodes: nodes, edges: edges });
            });
        }
        else if (ruleUnions) {
            ruleUnions.forEach(union => {
                const nodes = this.#ruleUnionToNodes(union);
                const edges = this.connectNodes(nodes);

                if (nodes.length > 0) {
                    subgroups.push({ nodes: nodes, edges: edges });
                }
            });
        }

        return subgroups;
    };


    #CONJUNCTION_AND = 1;
    #CONJUNCTION_OR = 2;
    #CONJUNCTION_THEN = 3;


    #getChildrenConjunction = (ruleUnion) => {
        const isTopLevel = (ruleUnion === this.game);

        if (isTopLevel) {
            const childRuleUnions = ruleUnion.ruleUnions.filter((ru) => ru.participantId === this.participantId);

            if (childRuleUnions.length > 0)
                return childRuleUnions[0].conjunction;
            else
                return this.#CONJUNCTION_THEN;
        }
        else {
            return ruleUnion.childrenConjunction;
        }
    };


    #isAndConjunction = (typeId) => (typeId === this.#CONJUNCTION_AND);
    #isOrConjunction = (typeId) => (typeId === this.#CONJUNCTION_OR);
    #isThenConjunction = (typeId) => (typeId === this.#CONJUNCTION_THEN);

    #isSequentialRuleUnion = (ruleUnion) => this.#isThenConjunction(this.#getChildrenConjunction(ruleUnion));


    connectNodes = (nodes) => {
        const colorMap = {
            'success': 'limegreen',
            'failure': 'red',
            // 'cancel': 'gold',
            'roundSuccess': 'limegreen',
            'roundFailure': 'red',
            // 'roundCancel': 'gold'
        };

        const edges = [];

        for (let i = 1; i < nodes.length; i++) {
            const sourceNode = nodes[i - 1];
            const targetNode = nodes[i];

            const reason = targetNode.data.reason;

            if (reason) {
                edges.push({
                    id: uuid(),
                    source: sourceNode.id,
                    sourceHandle: reason,
                    target: targetNode.id,
                    style: { ...theme.edges, stroke: colorMap[reason] }
                });
            }
            else {
                edges.push({
                    id: uuid(),
                    source: sourceNode.id,
                    target: targetNode.id,
                    style: { ...theme.edges }
                });
            }
        }

        return edges;
    };


    #gameToNodes = () => {
        return this.#ruleUnionToNodes(this.game);
    };


    #ruleUnionToNodes = (ruleUnion) => {
        const nodes = [];

        let childRules = ruleUnion.rules ?? [];

        let childRuleUnions = ruleUnion.ruleUnions ?? [];
        childRuleUnions = childRuleUnions.filter((ru) => ru.participantId === this.participantId);

        if (this.#isSequentialRuleUnion(ruleUnion)) {
            if ((childRules.length > 0) && (childRuleUnions.length === 0)) {
                childRules.forEach((childRule) => {
                    const nodeBuilderParams = {
                        rule: childRule
                    };

                    nodes.push(this.create_MilestoneNode(nodeBuilderParams, 'success'));  // Default reason for a sequence of rules is "success"
                });
            }
            else if ((childRules.length === 0) && (childRuleUnions.length > 0)) {
                childRuleUnions.forEach((childRuleUnion) => {
                    nodes.push(...this.#ruleUnionToNodes(childRuleUnion));
                });
            }
            else if ((childRules.length === 0) && (childRuleUnions.length === 0)) {
                // Ignore empty sequential ruleUnions
            }
        }
        else {  // "OR" or "AND"
            const reason = "success";  // TODO: should it come from the parent?

            if ((childRules.length > 0) && (childRuleUnions.length === 0)) {
                const nodeBuilderParams = {
                    baseUnion: ruleUnion,
                    rules: childRules,
                    ruleUnions: undefined,
                    childrenConjunction: this.#getChildrenConjunction(ruleUnion)
                };

                nodes.push(this.create_MilestoneGroupNode(nodeBuilderParams, reason));
            }
            else if ((childRules.length === 0) && (childRuleUnions.length > 0)) {
                const nodeBuilderParams = {
                    baseUnion: ruleUnion,
                    rules: undefined,
                    ruleUnions: childRuleUnions,
                    childrenConjunction: this.#getChildrenConjunction(ruleUnion)
                };

                nodes.push(this.create_MilestoneGroupNode(nodeBuilderParams, reason));
            }
            else if ((childRules.length === 0) && (childRuleUnions.length === 0)) {
                // Don't ignore empty parallel ruleUnions

                const nodeBuilderParams = {
                    baseUnion: ruleUnion,
                    rules: undefined,
                    ruleUnions: undefined,
                    childrenConjunction: this.#getChildrenConjunction(ruleUnion)
                };

                nodes.push(this.create_MilestoneGroupNode(nodeBuilderParams, reason));
            }
        }

        return nodes;
    };


    #createNode = (type, reason, externalValue, internalValue, nodeProps) => {
        return {
            id: uuid(),
            type: type,
            data: {
                reason: reason,
                externalValue: externalValue,
                internalValue: internalValue,
                nodeProps: nodeProps
            },
            position: { x: 0, y: 0 }
        };
    };


    create_JourneyStartNode = () => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId
        };

        return this.#createNode(
            'journeyStart',
            undefined,
            externalValue,
            convertToInternalValue_JourneyStartNode(externalValue),
            undefined
        );
    };


    create_JourneyEndNode = () => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId
        };

        return this.#createNode(
            'journeyEnd',
            undefined,
            externalValue,
            convertToInternalValue_JourneyEndNode(externalValue),
            undefined
        );
    };


    create_RoundStartNode = () => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId
        };

        return this.#createNode(
            'roundStart',
            undefined,
            externalValue,
            convertToInternalValue_RoundStartNode(externalValue),
            undefined
        );
    };


    create_RoundEndNode = () => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId
        };

        return this.#createNode(
            'roundEnd',
            undefined,
            externalValue,
            convertToInternalValue_RoundEndNode(externalValue),
            undefined
        );
    };


    create_JourneyNode = () => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId
        };

        return this.#createNode(
            'journey',
            undefined,
            externalValue,
            undefined,
            { isConnectable: false, hasModes: false }
        );
    };


    create_MilestoneNode = (params, reason = undefined) => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId,
            ...params
        };

        return this.#createNode(
            'milestone',
            reason,
            externalValue,
            convertToInternalValue_MilestoneNode(externalValue),
            undefined
        );
    };


    create_MilestoneGroupNode = (params, reason = undefined) => {
        const externalValue = {
            game: this.game,
            participantId: this.participantId,
            ...params
        };

        return this.#createNode(
            'milestoneGroup',
            reason,
            externalValue,
            undefined,
            undefined
        );
    };
}
