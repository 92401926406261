import { push, replace } from 'connected-react-router';
import { actionCreators as Alert } from './Alert';
import restHttp from '../../utils/restHttp';
import helper from '../../utils/helper';
import {
  DataType,
  NodeClass,
  NotificationType,
  NotificationPromptType,
} from '../../utils/enum';
import Base from './Base';

const base = new Base('game/game', 'Game');

const initialState = {
  list: {
    data: [],
    paging: {},
    preferTable: false,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Disabled',
        column: 'IsDisabled',
        type: DataType.Boolean,
        operator: '=',
        value: null,
      }, {
        name: 'Reward',
        column: 'RewardUnions.UnionRewards.RewardId',
        type: DataType.Number,
        operator: 'AnyAny',
        value: null,
        dataSourceKey: 'reward',
      }, {
        name: 'Published',
        column: 'PublishedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },
      ],
    },
  },
  roundList: {
    data: [],
    paging: { sortBy: 'startedOn', sortDescending: true },
    preferTable: true,
    filters: {
      isVisible: false,
      fields: [{
        name: 'Player',
        column: 'Player.Name',
        type: DataType.Text,
        operator: 'Contains',
        value: null,
      }, {
        name: 'Completed',
        column: 'CompletedOn',
        type: DataType.Boolean,
        operator: 'NotNull',
        value: null,
      },{
        name: 'Started',
        column: 'StartedOn',
        type: DataType.DateOnly,
        operator: 'DayIs',
        value: null,
      },],
    },
  },
  all: [],
  details: {},
};

export const actionCreators = {
  ...base.actionCreators,
  requestPost: (entity, publish) => dispatch => {
    dispatch({ type: base.requestPostType });

    restHttp
      .post(`${base.name}?publish=${publish}`, entity)
      .then(response => {
        dispatch({
          type: base.receivePostType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('Game created!'));
        dispatch(replace('/game/game'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestPut: (id, entity, publish) => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(`${base.name}/${id}?publish=${publish}`, entity)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess('Game updated!'));
        dispatch(push('/game/game'));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestRoundList: (id, pageIndex, pageSize, sortBy, sortDescending, filters) => dispatch => {
    dispatch({ type: base.customRequestType('round/list') });

    restHttp
      .get(`${base.name}/${id}/round`, restHttp.pageOptions(pageIndex, pageSize, sortBy, sortDescending, filters && filters.isVisible ? filters.fields : []))
      .then(response => {
        dispatch({
          type: base.customReceiveType('round/list'),
          data: response.data,
          paging: restHttp.pagingHeader(response),
        });
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  switchRoundDisplayStyle: () => dispatch => {
    dispatch({ type: base.customType('round/display/switch') });
  },
  switchRoundFilterVisibility: () => dispatch => {
    dispatch({ type: base.customType('round/filter/switch') });
  },
  requestSimulate: (game, actions) => dispatch => {
    dispatch({ type: base.customRequestType('simulation') });

    restHttp
      .post(`${base.name}/simulate`, { game, actions })
      .then(response => {
        dispatch({
          type: base.customReceiveType('simulation'),
          simulation: response.data,
        });
        const status = helper.simulationStatusText(response.data);
        dispatch(Alert
          .addSuccess(`Game ${status || 'not completed'}. Actions processed: ${response.data.actionsProcessed}`));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  requestUnpublish: id => dispatch => {
    dispatch({ type: base.requestPutType });

    restHttp
      .put(`${base.name}/${id}/unpublish`)
      .then(response => {
        dispatch({
          type: base.receivePutType,
          current: response.data,
        });
        dispatch(Alert.addSuccess(`${base.friendlyName} unpublished!`));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
  hideChildFilters: () => dispatch => {
    dispatch({ type: base.customType('child/filter/hide') });
  },
  clearAiMessage: () => dispatch => {
    dispatch({ type: base.customType('clear-ai-message') });
  },
  generateAiMessage: (nodeType, challengeName, challengeDescription, ruleNames, rewards, penalties, progressRatio, unionRuleId, gameId) => dispatch => {
    dispatch({ type: base.customRequestType('generate-ai-message') });

    var nodeClass;
    var notificationType;
    var notificationPromptType = NotificationPromptType.Copywrite

    if (nodeType === 'start') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundStarted]; }
    if (nodeType === 'end') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundCompleted]; }
    if (nodeType === 'progress') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundProgress]; }
    if (nodeType === 'milestoneProgress') { nodeClass = NodeClass[NodeClass.Milestone]; notificationType = NotificationType[NotificationType.MilestoneProgress]; }
    if (nodeType === 'success') { nodeClass = NodeClass[NodeClass.Milestone]; notificationType = NotificationType[NotificationType.MilestoneSucceeded]; }
    if (nodeType === 'failure') { nodeClass = NodeClass[NodeClass.Milestone]; notificationType = NotificationType[NotificationType.MilestoneFailed]; }
    if (nodeType === 'cancel') { nodeClass = NodeClass[NodeClass.Milestone]; notificationType = NotificationType[NotificationType.MilestoneCancelled]; }
    if (nodeType === 'roundSuccess') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundSucceeded]; }
    if (nodeType === 'roundFailure') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundFailed]; }
    if (nodeType === 'roundCancel') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.RoundCancelled]; }
    if (nodeType === 'journeyStart') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.GameStarted]; }
    if (nodeType === 'journeyEnd') { nodeClass = NodeClass[NodeClass.Journey]; notificationType = NotificationType[NotificationType.GameEnded]; }

    restHttp
      .post(`${base.name}/generateAiMessage`, { notificationType, notificationPromptType, challengeName, challengeDescription, ruleNames, rewards, penalties, progressRatio, nodeClass })
      .then(response => {
        dispatch({
          type: base.customReceiveType('generate-ai-message'),
          aiMessage: response.data,
          nodeType: nodeType,
          challengeName: challengeName,
          challengeDescription: challengeDescription,
          unionRuleId: unionRuleId,
          gameId: gameId,
        });
        dispatch(Alert.addSuccess(`AI message generated.`));
      })
      .catch(error => {
        dispatch(Alert.addError(error));
      });
  },
};

export const reducer = (state, action) => {
  const newState = state || initialState;

  if (
    action.type === base.receiveGetType
    || action.type === base.receivePostType
    || action.type === base.receivePutType
    || action.type === base.receiveEnableType
    || action.type === base.receiveDisableType
  ) {
    return {
      ...newState,
      details: {
        ...newState.details,
        [action.current.id]: action.current,
      },
      simulation: null,
    };
  }

  if (action.type === base.customReceiveType('simulation')) {
    return {
      ...newState,
      simulation: action.simulation,
    };
  }

  if (action.type === base.customType('clear-ai-message')) {
    return {
      ...newState,
      aimessage: null,
    };
  }

  if (action.type === base.customReceiveType('generate-ai-message')) {
    let title, gameStage;

    if (action.nodeType === "start") { gameStage = "Round Start"; }
    if (action.nodeType === "end") { gameStage = "Round End"; }
    if (action.nodeType === "progress") { gameStage = "Round Progress"; }
    if (action.nodeType === "success") { gameStage = "Milestone Success"; }
    if (action.nodeType === "milestoneProgress") { gameStage = "Milestone Progress"; }
    if (action.nodeType === "failure") { gameStage = "Milestone Fail"; }
    if (action.nodeType === "cancel") { gameStage = "Milestone Cancel"; }
    if (action.nodeType === "roundSuccess") { gameStage = "Round Success"; }
    if (action.nodeType === "roundFailure") { gameStage = "Round Fail"; }
    if (action.nodeType === "roundCancel") { gameStage = "Round Cancel"; }
    if (action.nodeType === "journeyStart") { gameStage = "Start"; }
    if (action.nodeType === "journeyEnd") { gameStage = "End"; }

    title = `Journey '${action.challengeName}' ${gameStage} Message`;

    return {
      ...newState,
      aimessage: {
        title: title,
        body: action.aiMessage,
        type: action.nodeType,
        unionRuleId: action.unionRuleId,
        gameId: action.gameId,
      },
    };
  }

  if (action.type === base.customReceiveType('round/list')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        data: action.data,
        paging: action.paging,
        filters: {
          ...newState.roundList.filters,
        },
      },
    };
  }

  if (action.type === base.customType('round/display/switch')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        preferTable: !newState.roundList.preferTable,
      },
    };
  }

  if (action.type === base.customType('round/filter/switch')) {
    return {
      ...newState,
      roundList: {
        ...newState.roundList,
        filters: {
          ...newState.roundList.filters,
          isVisible: !newState.roundList.filters.isVisible,
        },
      },
    };
  }

  if (action.type === base.customType('child/filter/hide')) {
    return {
      ...newState,
      roundList: {
        ...initialState.roundList,
      },
    };
  }

  return base.reducer(newState, action);
};
