import React from 'react';
import Default from '../../../images/default-image.jpg';
import { getResourceAddress } from '../../../utils/restHttp';

const ProfileImage = ({ profile, size }) => {
    if (profile === null) {
        return null;
    }
    else if (profile.imageId === null) {
        return (
            <img src={Default} width={`${size}px`} height={`${size}px`} alt={profile.name} />
        );
    }
    else {
        const src = getResourceAddress(`file/${profile.imageId}?width=${size}`);

        return (
            <img src={src} width={`${size}px`} height={`${size}px`} alt={profile.name} />
        );
    }
};

export default ProfileImage;
