import React from 'react';
import { Box } from '@mui/material';

const NodeEditMode = ({ children }) => {
    return (
      <Box>
          {children}
      </Box>
    );
};

export default NodeEditMode;
