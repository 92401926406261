import React, { useState } from 'react';
import {
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack, Switch,
    TextField,
    Typography
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import * as dayjs from 'dayjs';
import EditorBase from '../EditorBase';
import { TextFieldDeferred } from './TextFieldDeferred';

const DurationTimeframeEditor = ({ value, onChange, options }) => {
    const { amount, unit, isAligned, isAwaited } = value;
    const { enableAlign, enableAwait } = options;

    const handleAmountChange = (newValue) => {
        onChange({ ...value, amount: newValue });
    };

    const handleUnitChange = (event) => {
        onChange({ ...value, unit: event.target.value });
    };

    const handleAlignChange = (event) => {
        onChange({ ...value, isAligned: event.target.checked });
    };

    const handleAwaitChange = (event) => {
        onChange({ ...value, isAwaited: event.target.checked });
    };

    return (
        <Stack>
            <Stack direction="row" spacing={1}>
                <TextFieldDeferred
                    value={amount || ""}
                    onChangeDeferred={handleAmountChange}
                    variant="outlined"
                    size="small"
                    style={{ width: '50px' }}
                />
                <Select
                    value={unit || ""}
                    onChange={handleUnitChange}
                    size="small"
                >
                    <MenuItem value="d">day(s)</MenuItem>
                    <MenuItem value="w">week(s)</MenuItem>
                    <MenuItem value="m">month(s)</MenuItem>
                    <MenuItem value="y">year(s)</MenuItem>
                </Select>
            </Stack>
            { enableAlign && (
                <FormControlLabel
                    label="Align"
                    control={<Switch checked={isAligned} onChange={handleAlignChange}/>}
                    size="small"
                />
            )}
            { enableAwait && (
                <FormControlLabel
                    label="Await"
                    control={<Switch checked={isAwaited} onChange={handleAwaitChange}/>}
                    size="small"
                />
            )}
        </Stack>
    );
};

const CalendarTimeframeEditor = ({ value, onChange, options }) => {
    const { start, end, isAwaited } = value;
    const { enableStart, enableEnd, enableAwait } = options;

    const handleStartChange = (start) => {
        onChange({ ...value, start: start });
    };

    const handleEndChange = (end) => {
        onChange({ ...value, end: end });
    };

    const handleAwaitChange = (event) => {
        onChange({ ...value, isAwaited: event.target.checked });
    };

    return (
        <Stack>
            <Stack direction="row" spacing={1}>
                { enableStart && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Start on"
                            format="DD-MM-YYYY HH:mm"
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            ampm={false}
                            value={start}
                            onChange={handleStartChange}
                            size="small"/>
                    </LocalizationProvider>
                )}
                { enableEnd && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="End on"
                            format="DD-MM-YYYY HH:mm"
                            views={['year', 'month', 'day', 'hours', 'minutes']}
                            ampm={false}
                            value={end}
                            onChange={handleEndChange}
                            size="small"/>
                    </LocalizationProvider>
                )}
            </Stack>
            { enableAwait && (
                <FormControlLabel
                    label="Await"
                    control={<Switch checked={isAwaited} onChange={handleAwaitChange}/>}
                    size="small"
                />
            )}
        </Stack>
    );
};

const TimeframeEditor = ({ type, value, onChange, options }) => {
    const [cachedDurationValue, setCachedDurationValue] = useState({ amount: 1, unit: "d", isAligned: false, isAwaited: false });
    const [cachedCalendarValue, setCachedCalendarValue] = useState({ start: null, end: null, isAwaited: false });

    const durationValue = (type === "duration") ? value : cachedDurationValue;
    const calendarValue = (type === "calendar") ? value : cachedCalendarValue;

    const { enableNone, enableDuration, enableCalendar, durationOptions, calendarOptions } = options;

    const singleMode = [enableNone, enableDuration, enableCalendar].reduce((count, flag) => count + (flag ? 1 : 0), 0) === 1;

    const summary_duration = (amount, unit) => {
        switch (unit) {
            case "d":
                return amount + " day(s)";
            case "w":
                return amount + " week(s)";
            case "m":
                return amount + " month(s)";
            case "y":
                return amount + " year(s)";
        }
    };

    const summary_calendar = (start, end) => {
        const format = "MMM D";

        if (start !== null && end !== null) {
            return dayjs(start).format(format) + " - " + dayjs(end).format(format);
        }
        else if (start !== null && end === null) {
            return "From " + dayjs(start).format(format);
        }
        else if (start === null && end !== null) {
            return "Until " + dayjs(end).format(format);
        }
        else {
            return "None";
        }
    };

    const summary = () => {
        switch (type) {
            case "none":
                return "None";

            case "duration":
                return summary_duration(durationValue.amount, durationValue.unit);

            case "calendar":
                return summary_calendar(calendarValue.start, calendarValue.end);
        }
    };

    const handleTypeChange = (event) => {
        const newType = event.target.value;

        switch (newType) {
            case "none":
                onChange({ type: newType, value: undefined });
                break;

            case "duration":
                onChange({ type: newType, value: durationValue });
                break;

            case "calendar":
                onChange({ type: newType, value: calendarValue });
                break;
        }
    };

    const handleDurationChange = (newValue) => {
        setCachedDurationValue(newValue);
        onChange({ type: type, value: newValue });
    };

    const handleCalendarChange = (newValue) => {
        setCachedCalendarValue(newValue);
        onChange({ type: type, value: newValue });
    };

    return (
        <EditorBase title="Timeframe" summary={summary()}>
            {singleMode && (
                <>
                    {enableDuration && (type === "duration") && (
                        <DurationTimeframeEditor
                            value={durationValue}
                            onChange={handleDurationChange}
                            options={durationOptions}
                        />
                    )}

                    {enableCalendar && (type === "calendar") && (
                        <CalendarTimeframeEditor
                            value={calendarValue}
                            onChange={handleCalendarChange}
                            options={calendarOptions}
                        />
                    )}
                </>
            )}

            {!singleMode && (
                <RadioGroup name="duration" value={type} onChange={handleTypeChange}>
                    {enableNone && (
                        <FormControlLabel value="none" control={<Radio/>} label="None" />
                    )}

                    {enableDuration && (
                        <FormControlLabel value="duration" sx={{ alignItems: "flex-start" }} control={<Radio/>} label={
                            <Stack direction="row" spacing={1} alignItems="baseline">
                                <Typography>Duration:</Typography>
                                <DurationTimeframeEditor
                                    value={durationValue}
                                    onChange={handleDurationChange}
                                    options={durationOptions}
                                />
                            </Stack>
                        }/>
                    )}

                    {enableCalendar && (
                        <FormControlLabel value="calendar" sx={{ alignItems: "flex-start" }} control={<Radio/>} label={
                            <Stack direction="row" spacing={1} alignItems="baseline">
                                <Typography>Calendar:</Typography>
                                <CalendarTimeframeEditor
                                    value={calendarValue}
                                    onChange={handleCalendarChange}
                                    options={calendarOptions}
                                />
                            </Stack>
                        }/>
                    )}
                </RadioGroup>
            )}
        </EditorBase>
    );
};

export default TimeframeEditor;
