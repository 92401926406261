import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';

export const TextFieldDeferred = ({ label, value, onChangeDeferred, ...props }) => {
    const [internalValue, setInternalValue] = useState(value);

    // Update internal state when value prop changes
    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setInternalValue(newValue);
        if (onChangeDeferred) {
            onChangeDeferred(newValue);
        }
    };

    const handleBlur = () => {
        if (internalValue !== value) {
            onChangeDeferred(internalValue);
        }
    };
    return (
        <TextField
            label={label}
            value={internalValue}
            onChange={handleChange}
            onBlur={handleBlur}
            {...props}
        />
    );
};