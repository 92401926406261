import React, { useState } from 'react';
import { Handle, Position } from 'reactflow';
import { Box, Divider, IconButton, Paper } from '@mui/material';
import { ClickAwayListener } from '@mui/base';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StraightIcon from '@mui/icons-material/Straight';
import ForkRightIcon from '@mui/icons-material/ForkRight';
// import RouteIcon from "@mui/icons-material/Route";

import { theme } from "./theme"

const NodeHandle = ({ type, reason, mode, connected, onCollapse, onExpand, onAddMilestone, onAddGroup, /* onAddJourney, */ ...other }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const openMenu = (event) => {
        setIsMenuOpen(true);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const collapse = () => {
        closeMenu();
        setIsCollapsed(true);
        onCollapse();
    };

    const expand = () => {
        closeMenu();
        setIsCollapsed(false);
        onExpand();
    };

    const addMilestone = () => {
        closeMenu();
        onAddMilestone();
    };

    const addGroup = () => {
        closeMenu();
        onAddGroup();
    };

    // const addJourney = () => {
    //     closeMenu();
    //     onAddJourney();
    // };

    let offset;
    let color;

    switch (reason) {
        case "success":
            offset = "50%";
            color = "limegreen";
            break;
        case "failure":
            offset = "75%";
            color = "red";
            break;
        // case "cancel":
        //     offset = "80%";
        //     color = "gold";
        //     break;
        default:
            offset = "50%";
            color = "gray";
            break;
    }

    const styleBase = {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        borderColor: color,
        backgroundColor: 'white',
        cursor: 'unset'
    };

    const styleBase_Small = {
        ...styleBase,
        width: '28px',
        height: '28px',
    };

    const styleBase_Medium = {
        ...styleBase,
        width: '40px',
        height: '40px',
    };

    const styleTarget_Small = {
        ...styleBase_Small,
        left: '50%',
        top: '-14px'
    };

    const styleSource_Small = {
        ...styleBase_Small,
        left: offset,
        bottom: '-14px'
    };

    const styleSource_Medium = {
        ...styleBase_Medium,
        left: offset,
        bottom: '-20px'
    };

    const styleSource_MenuClosed = {
        ...styleSource_Medium
    };

    const styleSource_MenuOpen4x = {
        ...styleSource_Medium,
        height: '160px',
    };

    const styleSource_MenuOpen5x = {
        ...styleSource_Medium,
        height: '200px',
        bottom: '-60px'
    };

    const style_Base = { cursor: 'unset' };
    const style_Theme = { ...theme.nodes.controls };

    const style_OuterBorder = {
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
    };

    const style_InnerBorder = {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '4px',
        borderColor: color,
    };

    if (type === "target") {
        const style_Position = { position: 'absolute', left: '50%', top: '-14px' };
        const style_Size = { width: '28px', height: '28px' };
        const s = { ...style_Base, ...style_Position, ...style_Size, ...style_OuterBorder, ...style_Theme };
        return (
            <Handle type="target" {...other} style={s}>
                <Box sx={{ width: '100%', height: '100%', ...style_InnerBorder }}/>
            </Handle>
        );
    }
    else if (type === "source") {
        if (mode === "edit") {
            if (isCollapsed) {
                const style_Position = { position: 'absolute', left: offset, bottom: '-20px' };
                const style_Size = { width: '40px', height: '40px' };
                const s = { ...style_Base, ...style_Position, ...style_Size, ...style_OuterBorder, ...style_Theme };
                return (
                    <Handle type="source" {...other} style={s}>
                        <Box sx={{ width: '100%', height: '100%', ...style_InnerBorder }}>
                            <IconButton onClick={expand}><ExpandMoreIcon fontSize="inherit" sx={{color: color}}/></IconButton>
                        </Box>
                    </Handle>
                );
            }
            else if (isMenuOpen) {
                const style_Position = { position: 'absolute', left: offset, bottom: connected ? '-60px' : '-20px' };
                const style_Size = { width: '40px', height: connected ? '200px' : '160px' };
                const s = { ...style_Base, ...style_Position, ...style_Size };
                return (
                    <Handle type="source" {...other} style={s}>
                        <ClickAwayListener onClickAway={closeMenu}>
                            <Paper elevation={3}>
                                <Box sx={{ width: '100%', height: '100%', ...style_InnerBorder }}>
                                    <IconButton onClick={addMilestone}><StraightIcon fontSize="inherit" sx={{ color: color, transform: 'rotate(135deg)' }}/></IconButton>
                                    <IconButton onClick={addGroup}><ForkRightIcon fontSize="inherit" sx={{ color: color, transform: 'rotate(135deg)' }}/></IconButton>
                                    {/* <IconButton onClick={addJourney}><RouteIcon fontSize="inherit" sx={{ color: color, transform: 'rotate(135deg)' }}/></IconButton> */}
                                    <Divider/>
                                    <IconButton onClick={closeMenu}><MoreHorizIcon fontSize="inherit" sx={{ color: color }}/></IconButton>
                                    {connected && (
                                      <>
                                          <Divider/>
                                          <IconButton onClick={collapse}><ExpandLessIcon fontSize="inherit" sx={{ color: color }}/></IconButton>
                                      </>
                                    )}
                                </Box>
                            </Paper>
                        </ClickAwayListener>
                    </Handle>
                );
            }
            else {
                const style_Position = { position: 'absolute', left: offset, bottom: '-20px' };
                const style_Size = { width: '40px', height: '40px' };
                const s = { ...style_Base, ...style_Position, ...style_Size, ...style_OuterBorder, ...style_Theme };
                return (
                    <Handle type="source" {...other} style={s}>
                        <Box sx={{ width: '100%', height: '100%', ...style_InnerBorder }}>
                            <IconButton onClick={openMenu}><MoreHorizIcon fontSize="inherit" sx={{ color: color }}/></IconButton>
                        </Box>
                    </Handle>
                );
            }
        }
        else if (mode === "view") {
            const style_Position = { position: 'absolute', left: offset, bottom: '-20px' };
            const style_Size = { width: '28px', height: '28px' };
            const s = { ...style_Base, ...style_Position, ...style_Size, ...style_OuterBorder, ...style_Theme };
            return (
                <Handle type="source" {...other} style={s}>
                    <Box sx={{ width: '100%', height: '100%', ...style_InnerBorder }}>
                        {connected && isCollapsed && (
                          <IconButton size="small" onClick={expand}><ExpandMoreIcon fontSize="inherit" sx={{ color: color }}/></IconButton>
                        )}
                        {connected && !isCollapsed && (
                          <IconButton size="small" onClick={collapse}><ExpandLessIcon fontSize="inherit" sx={{ color: color }}/></IconButton>
                        )}
                    </Box>
                </Handle>
            );
        }
    }
};

export default NodeHandle;
