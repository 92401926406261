import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import NameDescriptionEditor from '../controls/NameDescriptionEditor';
import TimeframeEditor from '../controls/TimeframeEditor';
import ParticipantEditor from '../controls/ParticipantEditor';
import OutcomeEditor from '../controls/OutcomeEditor';
import JourneyContext from '../JourneyContext';

const JourneyStartNodeEdit = ({ value, onChange, outcomeTypes }) => {
    const { name, description, timeframe, participants, message, incentives } = value;

    const setNameDescription = ({name, description}) => {
        onChange({ ...value, name, description });
    };

    const setTimeframe = (timeframe) => {
        onChange({ ...value, timeframe });
    };

    const setParticipants = (participants) => {
        onChange({ ...value, participants });
    };

    const setMessage = (message) => {
        onChange({ ...value, message });
    };

    const setIncentives = (incentives) => {
        onChange({ ...value, incentives });
    };

    return (
        <Stack useFlexGap>
            <NameDescriptionEditor value={{ name, description }} onChange={setNameDescription} />

            <TimeframeEditor
                type={timeframe.type}
                value={timeframe.value}
                onChange={setTimeframe}
                options={{
                    enableNone: false,
                    enableDuration: false,
                    enableCalendar: true,
                    durationOptions: undefined,
                    calendarOptions: { enableStart: true, enableEnd: true, enableAwait: false }
                }}
            />

            <ParticipantEditor value={participants} onChange={setParticipants} />

            <OutcomeEditor types={outcomeTypes}
                           messages={{ journeyStart: message }} onMessageChange={(messages) => setMessage(messages.journeyStart)}
                           incentives={{ journeyStart: incentives }} onIncentiveChange={(incentives) => setIncentives(incentives.journeyStart)} />
        </Stack>
    );
};

export default JourneyStartNodeEdit;
