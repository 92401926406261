import React, { useContext, useMemo, useState } from 'react';
import { useReactFlow } from 'reactflow';
import {
    Box
} from "@mui/material";

import JourneyContext from '../JourneyContext';

import Node from "../Node";
import NodeEditMode from "../NodeEditMode";
import NodeViewMode from "../NodeViewMode";
import {theme} from "../theme";
import MilestoneNodeEdit from './MilestoneNodeEdit';
import MilestoneNodeView from './MilestoneNodeView';
import { applyToExternalValue_MilestoneNode } from '../convert';

const MilestoneNode = (props) => {
    const {
        id,
        data: {
            reason,
            externalValue,
            internalValue,
            nodeProps
        }
    } = props;

    const { isGameMode, isLeaderboardMode, onNodeValueUpdated } = useContext(JourneyContext);

    const rf = useReactFlow();

    const outcomeTypes = useMemo(() => {
        if (isGameMode)
            return ["success", "milestoneProgress", "failure", /* "cancel" */];
        else if (isLeaderboardMode)
            return [];
    }, []);

    const updateValue = (updatedInternalValue) => {
        onNodeValueUpdated((currentExternalValue) => {
            const updatedExternalValue = applyToExternalValue_MilestoneNode(updatedInternalValue, currentExternalValue);

            rf.setNodes((nodes) => nodes.map((node) => {
                if (node.id !== id) return node;

                return {
                    ...node,
                    data: {
                        ...node.data,
                        internalValue: updatedInternalValue,
                        externalValue: updatedExternalValue
                    }
                };
            }));

            return updatedExternalValue;
        });
    };

    return (
        <Node title="Milestone" reason={reason} {...nodeProps}>
            <NodeEditMode>
                <Box sx={{ mx: 2, my: 6 }}>
                    <MilestoneNodeEdit value={internalValue} onChange={updateValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeEditMode>
            <NodeViewMode>
                <Box sx={{ mx: 2, my: 3 }}>
                    <MilestoneNodeView value={internalValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeViewMode>
        </Node>
    );
};

export default MilestoneNode;
