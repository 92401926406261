import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { actionCreators } from '../../store/modules/Player';
import List, { ListPropTypes } from '../../containers/List';
import date from '../../utils/date';
import ListImage from '../../containers/ListImage';

const PlayerRewardList = props => {
  const {
    id, list, requestRewardList, switchRewardDisplayStyle, switchRewardFilterVisibility,
  } = props;

  const columns = [
    {
      id: 'imageId',
      Header: '',
      Cell: row => (
        <ListImage imageSize={32} className="table-image"
          items={list.data.map(r => r.reward)} currentItem={row.original.reward} />
      ),
      sortable: false,
      maxWidth: 40,
    },
    {
      id: 'reward.name',
      Header: 'Reward',
      accessor: 'reward.name',
      sortable: false,
    },
    {
      id: 'round.game.name',
      Header: 'Source',
      accessor: 'round.game.name',
      sortable: false,
    },
    {
      id: 'round.completedOn',
      Header: 'Completed',
      accessor: 'round.completedOn',
      Cell: cell => (cell.value ? date.long(cell.value) : 'In progress'),
      sortable: true,
      maxWidth: 165,
    },
    {
      accessor: 'reward.id',
      Cell: cell => (
        <LinkContainer to={`/reward/${cell.value}`} exact>
          <div className="gaas-tile-btn">See More</div>
        </LinkContainer>
      ),
      maxWidth: 150,
    },
  ];
  return (
    <List title="Rewards"
      subtitle="Reward is a prize that a player receives/loses during a game"
      requestList={(pageIndex, pageSize, sortBy, sortDescending, filters) => requestRewardList(id, pageIndex, pageSize, sortBy, sortDescending, filters)}
      {...list.paging}
      preferTable={list.preferTable}
      filters={list.filters}
      tableData={list.data}
      tableColumns={columns}
      switchDisplayStyle={switchRewardDisplayStyle}
      switchFilterVisibility={switchRewardFilterVisibility}>
      {list.data.map(roundReward => (
        <div key={`${roundReward.round.id} ${roundReward.reward.id}`}>
          <div className="gaas-tile">
            <div className="gaas-tile-image">
              <ListImage items={list.data.map(r => r.reward)} currentItem={roundReward.reward}
                className={roundReward.reward.isDisabled ? 'avatar lg disabled' : undefined} />
            </div>
            <div className="gaas-tile-heading h-img">
              <span className="text-ellipsis">{roundReward.reward.name}</span>
            </div>
            <div className="gaas-tile-body">
              <div className="m-t-md m-b-md padder">
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Source</span>
                    {' : '}
                    <span>{roundReward.round.game.name}</span>
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <span className="text-underline">Completed</span>
                    {' : '}
                    <span>{roundReward.round.completedOn ? date.long(roundReward.round.completedOn) : '-'}</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="gaas-tile-footer m-t-sm" style={{ borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }}>
              <LinkContainer to={`/reward/${roundReward.reward.id}`} exact>
                <div className="gaas-tile-btn">See More</div>
              </LinkContainer>
            </div>
            <div className="clear" />
          </div>
        </div>
      ))}
    </List>
  );
};

PlayerRewardList.propTypes = {
  id: PropTypes.number.isRequired,
  list: ListPropTypes.isRequired,
  requestRewardList: PropTypes.func.isRequired,
  switchRewardDisplayStyle: PropTypes.func.isRequired,
  switchRewardFilterVisibility: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    list: state.player.rewardList,
  }),
  dispatch => bindActionCreators(actionCreators, dispatch),
)(PlayerRewardList);
