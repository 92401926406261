import React, { useState } from 'react';
import { Box, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ClearIcon from '@mui/icons-material/Clear';

import { theme } from "./theme"

const NodeControls = ({ onEditMode, onViewMode, onRemove }) => {
    const [displayMode, setDisplayMode] = useState("view");

    const handleDisplayModeEdit = () => {
        setDisplayMode("edit");

        if (onEditMode) {
            onEditMode();
        }
    };

    const handleDisplayModeView = () => {
        setDisplayMode("view");

        if (onViewMode) {
            onViewMode();
        }
    };

    const handleRemove = () => {
        if (onRemove) {
            onRemove();
        }
    };

    return (
        <Box sx={{ position: 'absolute', right: '1rem', top: '-12px', zIndex: 1 }}>
            <Stack direction="row" spacing={1}>
                {(displayMode === "view") && (
                    <Box sx={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', ...theme.nodes.controls }}>
                        <ToggleButtonGroup exclusive size="small" className="nodrag nopan">
                            <ToggleButton size="small" onClick={handleDisplayModeEdit}><ModeEditIcon fontSize="small"/></ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                )}

                {(displayMode === "edit") && (
                    <>
                        {onRemove && (
                            <Box sx={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', ...theme.nodes.controls }}>
                                <ToggleButtonGroup exclusive size="small" className="nodrag nopan">
                                    <ToggleButton size="small" onClick={handleRemove}><ClearIcon fontSize="small"/></ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        )}

                        <Box sx={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '5px', ...theme.nodes.controls }}>
                            <ToggleButtonGroup exclusive size="small" className="nodrag nopan">
                                <ToggleButton size="small" onClick={handleDisplayModeView}><VisibilityIcon fontSize="small"/></ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </>
                )}
            </Stack>
        </Box>
    );
};

export default NodeControls;
