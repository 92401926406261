import React from 'react';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import StraightIcon from '@mui/icons-material/Straight';
import ForkRightIcon from '@mui/icons-material/ForkRight';
// import RouteIcon from '@mui/icons-material/Route';

const AddButtonGroup = ({ onAddMilestone, onAddGroup, /* onAddJourney */ }) => {
    return (
        <List disablePadding className="nodrag nopan" sx={{ backgroundColor: "white", borderRadius: '5px' }}>
            <ListItemButton onClick={onAddMilestone}>
                <ListItemIcon><StraightIcon fontSize="large" sx={{ transform: 'rotate(135deg)' }} /></ListItemIcon>
                <ListItemText>Add a milestone</ListItemText>
            </ListItemButton>
            <Divider variant="middle" />
            <ListItemButton onClick={onAddGroup}>
                <ListItemIcon><ForkRightIcon fontSize="large" sx={{ transform: 'rotate(135deg)' }} /></ListItemIcon>
                <ListItemText>Add a group</ListItemText>
            </ListItemButton>
{/*
            <Divider variant="middle" />
            <ListItemButton onClick={onAddJourney}>
                <ListItemIcon><RouteIcon fontSize="large" sx={{ transform: 'rotate(135deg)' }} /></ListItemIcon>
                <ListItemText>Add a sub-journey</ListItemText>
            </ListItemButton>
*/}
        </List>
    );
};

export default AddButtonGroup;
