import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import TargetEditor from '../controls/TargetEditor';
import TimeframeEditor from '../controls/TimeframeEditor';
import OutcomeEditor from '../controls/OutcomeEditor';
import JourneyContext from '../JourneyContext';

const MilestoneNodeEdit = ({ value, onChange, outcomeTypes }) => {
    const { target, timeframe, messages, incentives, unionRuleId, incentivesProgressConditions } = value;

    const { isGameMode, isLeaderboardMode } = useContext(JourneyContext);

    const setTarget = (target) => {
        onChange({ ...value, target });
    };

    const setTimeframe = (timeframe) => {
        onChange({ ...value, timeframe });
    };

    const setMessages = (messages) => {
        onChange({ ...value, messages });
    };

    const setIncentives = (incentives) => {
        onChange({ ...value, incentives });
    };

    const setIncentivesProgressConditions = (incentivesProgressConditions) => {
        onChange({ ...value, incentivesProgressConditions });
    };

    return (
        <Stack useFlexGap>
            <TargetEditor value={target} onChange={setTarget} />

            {isGameMode && (
                <TimeframeEditor
                    type={timeframe.type}
                    value={timeframe.value}
                    onChange={setTimeframe}
                    options={{
                        enableNone: true,
                        enableDuration: true,
                        enableCalendar: false,
                        durationOptions: { enableAlign: false, enableAwait: false },
                        calendarOptions: undefined
                    }}
                />
            )}

            {isGameMode && (
                <OutcomeEditor
                    types={outcomeTypes} unionRuleId={unionRuleId}
                    messages={messages} incentives={incentives}
                    incentivesProgressConditions={incentivesProgressConditions} onIncentivesProgressConditionsChange={setIncentivesProgressConditions}
                    onMessageChange={setMessages} onIncentiveChange={setIncentives}
                />
            )}
        </Stack>
    );
};

export default MilestoneNodeEdit;
