import React from 'react';
import { Box, Button, Paper, Stack } from '@mui/material';

const JourneyControls = (props) => {
    const {
        isFullscreen, onToggleFullscreen
    } = props;

    return (
        <Paper square elevation={5} sx={{ position: 'absolute', top: '10px', right: '25px', zIndex: 100, width: 250 }}>
            <Box sx={{ p: 2 }}>
                <Stack spacing={1}>
                    {!isFullscreen && (
                        <Button variant="outlined" onClick={() => onToggleFullscreen(true)}>Enter Full Screen</Button>
                    )}

                    {isFullscreen && (
                        <Button variant="contained" onClick={() => onToggleFullscreen(false)}>Exit Full Screen</Button>
                    )}
                </Stack>
            </Box>
        </Paper>
    );
};

export default JourneyControls;
