import { getIncomers, getOutgoers, getConnectedEdges } from 'reactflow';

export function getIncoming(rf, targetNode) {
    // Edges that are incoming to the node
    const incomingEdges =
        getConnectedEdges([targetNode], rf.getEdges())
            .filter(e => e.target === targetNode.id);

    // Nodes that are incoming to the node
    const incomingNodes = getIncomers(targetNode, rf.getNodes(), incomingEdges);

    return [incomingNodes, incomingEdges];
}

export function hasIncoming(rf, targetNode) {
    const [incomingNodes, incomingEdges] = getIncoming(rf, targetNode);
    return incomingNodes.length > 0;
}

export function getOutgoingFromHandle(rf, sourceNode, sourceHandleId) {
    // Edges that are outgoing from the specified handle of the node
    const outgoingEdges =
        getConnectedEdges([sourceNode], rf.getEdges())
            .filter(e => e.source === sourceNode.id)
            .filter(e => e.sourceHandle === sourceHandleId);

    // Nodes that are outgoing from the node via the specified handle
    const outgoingNodes = getOutgoers(sourceNode, rf.getNodes(), outgoingEdges);

    if (outgoingNodes.length > 0) {
        return [outgoingNodes[0], outgoingEdges[0]];
    }
    else {
        return [null, null];
    }
}

export function getOutgoing(rf, sourceNode) {
    // Edges that are outgoing from the node
    const outgoingEdges =
        getConnectedEdges([sourceNode], rf.getEdges())
            .filter(e => e.source === sourceNode.id);

    // Nodes that are outgoing from the node
    const outgoingNodes = getOutgoers(sourceNode, rf.getNodes(), outgoingEdges);

    return [outgoingNodes, outgoingEdges];
}

export function hasOutgoingFromHandle(rf, sourceNode, sourceHandleId) {
    const [outgoingNode, outgoingEdge] = getOutgoingFromHandle(rf, sourceNode, sourceHandleId);
    return outgoingNode !== null;
}

export function hasOutgoing(rf, sourceNode) {
    const [outgoingNodes, outgoingEdges] = getOutgoing(rf, sourceNode);
    return outgoingNodes.length > 0;
}

export function getDescendingFromHandle(rf, sourceNode, sourceHandleId) {
    const [outgoingNode, outgoingEdge] = getOutgoingFromHandle(rf, sourceNode, sourceHandleId);

    const descendingNodes = [];
    const descendingEdges = [];
    const queue = [];

    if (outgoingNode !== null) {
        descendingNodes.push(outgoingNode);
        descendingEdges.push(outgoingEdge);
        queue.push(outgoingNode);
    }

    while (queue.length > 0) {
        const node = queue.shift();
        const [outgoingNodes, outgoingEdges] = getOutgoing(rf, node);

        descendingNodes.push(...outgoingNodes);
        descendingEdges.push(...outgoingEdges);
        queue.push(...outgoingNodes);
    }

    return [descendingNodes, descendingEdges];
}

export function getDescending(rf, sourceNode) {
    const descendingNodes = [];
    const descendingEdges = [];
    const queue = [sourceNode];

    while (queue.length > 0) {
        const node = queue.shift();
        const [outgoingNodes, outgoingEdges] = getOutgoing(rf, node);

        descendingNodes.push(...outgoingNodes);
        descendingEdges.push(...outgoingEdges);
        queue.push(...outgoingNodes);
    }

    return [descendingNodes, descendingEdges];
}
