import React, { useState, useMemo, useContext } from 'react';
import {
    Avatar,
    Box,
    Chip,
    Divider,
    IconButton,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Stack, Switch
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ProfileImage from './controls/ProfileImage';

const JourneyProfiles = ({ initialProfiles, allProfiles, onSelect, onAdd, onRemove }) => {
    const [profiles, setProfiles] = useState(initialProfiles);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [copy, setCopy] = useState(false);

    const unusedProfiles = allProfiles.filter((a) => !profiles.some((p) => p.id === a.id));

    const isSelected = (profile) => {
        return (profile?.id === selectedProfile?.id);
    };

    const selectProfile = (profile) => {
        if (!isSelected(profile)) {
            setSelectedProfile(profile);
            if (onSelect) onSelect(profile);
        }
    };

    const addProfile = (profile) => {
        setProfiles([...profiles, profile]);

        if (onAdd) {
            if (copy)
                onAdd(profile, selectedProfile);
            else
                onAdd(profile);
        }

        selectProfile(profile);
    };

    const removeProfile = (profile) => {
        if (isSelected(profile)) {
            selectProfile(null);
        }

        setProfiles(profiles.filter((p) => p.id !== profile.id));
        if (onRemove) onRemove(profile);
    };

    const handleMenuOpen = (event) => {
        setMenuAnchor(event.currentTarget);
        setCopy(false);
    };

    const handleMenuClose = () => {
        setMenuAnchor(null);
    };

    const handleMenuChoice = (profile) => {
        setMenuAnchor(null);
        addProfile(profile);
    };

    const ProfileChip = ({ profile }) => {
        const theme = useTheme();

        const color = isSelected(profile) ? "warning" : "info";

        const onClick = (() => selectProfile(profile));
        const onDelete = (() => removeProfile(profile));

        if (profile) {
            const avatar= (
                <Avatar><ProfileImage profile={profile} size="24"/></Avatar>
            );

            return (
                <Chip size="large" variant="filled" color={color} label={profile.name} avatar={avatar} onClick={onClick} onDelete={onDelete} sx={{ boxShadow: theme.shadows[5] }} />
            );
        }
        else {
            const avatar= (
                <Avatar><ProfileImage profile={null} size="24"/></Avatar>
            );

            return (
                <Chip size="large" variant="filled" color={color} label="Default" avatar={avatar} onClick={onClick} sx={{ boxShadow: theme.shadows[5] }} />
            );
        }
    };

    return (
        <Box sx={{ position: 'absolute', top: '10px', left: '25px', zIndex: 100 }}>
            <Stack direction="row" spacing={1} alignItems="center">
                <ProfileChip profile={null} />

                {
                    profiles.map((profile) => (
                        <ProfileChip key={profile.id} profile={profile} />
                    ))
                }

                <IconButton size="medium" onClick={handleMenuOpen} title="Add profile">
                    <AddCircleOutlineIcon fontSize="large" />
                </IconButton>

                <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
                    {
                        (unusedProfiles.length === 0) && (
                            <ListSubheader>All available profiles have already been used.</ListSubheader>
                        )
                    }
                    {
                        (unusedProfiles.length > 0) && (
                            <ListSubheader>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Switch checked={copy} onChange={() => setCopy(!copy)}/>
                                    <span>Copy settings from</span>
                                    <Chip size="small" variant="outlined" label={selectedProfile ? selectedProfile.name : "Default"}/>
                                </Stack>
                            </ListSubheader>
                        )
                    }
                    {
                        (unusedProfiles.length > 0) && (
                            <Divider/>
                        )
                    }
                    {
                        unusedProfiles.map((profile) => (
                            <MenuItem key={profile.id} onClick={() => handleMenuChoice(profile)}>
                                <ListItemAvatar><Avatar><ProfileImage profile={profile} size="40"/></Avatar></ListItemAvatar>
                                <ListItemText primary={profile.name} />
                            </MenuItem>
                        ))
                    }
                </Menu>
            </Stack>
        </Box>
    );
};

export default JourneyProfiles;
