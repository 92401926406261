import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import { IncentiveStage, NotificationType } from '../../utils/enum';


export const newId = () => {
    const int32 = 2147483648;
    return Math.floor(Date.now() * Math.random()) % int32;
};


export const filterGameByProfile = (game, profileId) => {
    return {
        ...game,
        ruleUnions: game.ruleUnions.filter((r) => r.participantId === profileId),
        gameNotifications: game.gameNotifications.filter((n) => n.participantId === profileId),
        rewardUnions: game.rewardUnions.filter((r) => r.participantId === profileId)
    };
};


export const convertToInternalValue_JourneyStartNode = (externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    return {
        name: game.name,
        description: game.description,
        timeframe: {
            // TODO: "none" when startOn and endOn are empty (?)
            type: "calendar",
            value: {
                start: game.startOn ? dayjs(game.startOn) : null,
                end: game.endOn ? dayjs(game.endOn) : null,
                isAwaited: false
            }
        },
        participants: game.participantUnions,
        message: getMessage(game, NotificationType.GameStarted, filter),
        incentives: getIncentives(game, IncentiveStage.GameStarted, filter)
    };
};


export const convertToInternalValue_JourneyEndNode = (externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    return {
        message: getMessage(game, NotificationType.GameEnded, filter),
        incentives: getIncentives(game, IncentiveStage.GameEnded, filter)
    };
};


export const convertToInternalValue_RoundStartNode = (externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    return {
        timeframe: getTimeframe(game),
        occurrences: {
            perPlayer: game.maxOccurrencesPlayer,
            perJourney: game.maxOccurrencesGlobal
        },
        messages: {
            start: getMessage(game, NotificationType.RoundStarted, filter),
            progress: getMessage(game, NotificationType.RoundProgress, filter)
        },
        incentives: {
            start: getIncentives(game, IncentiveStage.RoundStarted, filter),
            progress: getIncentives(game, IncentiveStage.RoundProgress, filter)
        },
        incentivesProgressConditions: {
            conditions: getProgressConditions(game, IncentiveStage.RoundProgress)
        }
    }
};


export const convertToInternalValue_RoundEndNode = (externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    return {
        messages: {
            roundSuccess: getMessage(game, NotificationType.RoundSucceeded, filter),
            roundFailure: getMessage(game, NotificationType.RoundFailed, filter),
            // roundCancel: getMessage(game, NotificationType.RoundCancelled, filter)
        },
        incentives: {
            roundSuccess: getIncentives(game, IncentiveStage.RoundSucceeded, filter),
            roundFailure: getIncentives(game, IncentiveStage.RoundFailed, filter),
            // roundCancel: getIncentives(game, IncentiveStage.RoundCancelled, filter)
        }
    };
};


export const convertToInternalValue_MilestoneNode = (externalValue) => {
    const { game, participantId, rule } = externalValue;

    const filter = { participantId: participantId, unionRuleId: rule.unionEntityId };

    let timeframe = { type: "none", value: undefined };

    if (rule.periodValue && rule.periodUnit) {
        timeframe = { type: "duration", value: { amount: rule.periodValue, unit: rule.periodUnit } }
    }

    return {
        target: {
            id: rule.id,
            name: rule.name
        },
        timeframe: timeframe,
        messages: {
            success: getMessage(game, NotificationType.MilestoneSucceeded, filter),
            milestoneProgress: getMessage(game, NotificationType.MilestoneProgress, filter),
            failure: getMessage(game, NotificationType.MilestoneFailed, filter),
            // cancel: getMessage(game, NotificationType.MilestoneCancelled, filter)
        },
        incentives: {
            success: getIncentives(game, IncentiveStage.MilestoneSucceeded, filter),
            milestoneProgress: getIncentives(game, IncentiveStage.MilestoneProgress, filter),
            failure: getIncentives(game, IncentiveStage.MilestoneFailed, filter),
            // cancel: getIncentives(game, IncentiveStage.MilestoneCancelled, filter)
        },
        unionRuleId: rule.unionEntityId,
        incentivesProgressConditions: {
            conditions: getProgressConditions(game, IncentiveStage.MilestoneProgress)
        }

    };
};


export const emptyInternalValue_MilestoneNode = () => {
    return {
        target: {
            id: null,
            name: null
        },
        timeframe: { type: "none", value: undefined },
        messages: {
            success: null,
            milestoneProgress: null,
            failure: null,
            // cancel: null
        },
        incentives: {
            success: [],
            milestoneProgress: [],
            failure: [],
            // cancel: []
        },
        unionRuleId: null,
        incentivesProgressConditions: {
            conditions: []
        }
    };
};


const findRule = (root, unionEntityId) => {
    if (root.rules) {
        for (const rule of root.rules) {
            if (rule.unionEntityId === unionEntityId) {
                return rule;
            }
        }
    }

    if (root.ruleUnions) {
        for (const ruleUnion of root.ruleUnions) {
            const foundRule = findRule(ruleUnion, unionEntityId);

            if (foundRule) {
                return foundRule;
            }
        }
    }

    return null;
};


export const applyToExternalValue_JourneyStartNode = (internalValue, externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    const updatedGame = {
        ...game,

        name: internalValue.name,
        description: internalValue.description,

        startOn: internalValue.timeframe.value.start &&
            internalValue.timeframe.value.start.isValid()
            ? internalValue.timeframe.value.start.utc().toISOString()
            : null,
        endOn: internalValue.timeframe.value.end &&
            internalValue.timeframe.value.end.isValid()
            ? internalValue.timeframe.value.end.utc().toISOString()
            : null,

        participantUnions: internalValue.participants,

        gameNotifications: applyMessage(internalValue.message, game, NotificationType.GameStarted, filter),
        rewardUnions: applyIncentives(internalValue.incentives, game, IncentiveStage.GameStarted, filter)
    };

    return { game: updatedGame, participantId };
};


export const applyToExternalValue_JourneyEndNode = (internalValue, externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    const updatedGame = {
        ...game,
        gameNotifications: applyMessage(internalValue.message, game, NotificationType.GameEnded, filter),
        rewardUnions: applyIncentives(internalValue.incentives, game, IncentiveStage.GameEnded, filter)
    };

    return { game: updatedGame, participantId };
};


export const applyToExternalValue_RoundStartNode = (internalValue, externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    const updatedGame = {
        ...game,

        period: timeframeToPeriod(internalValue.timeframe),

        periodValue: internalValue.timeframe.value?.amount ?? null,
        periodUnit: internalValue.timeframe.value?.unit ?? null,
        periodAligned: internalValue.timeframe.value?.isAligned ?? null,
        periodAwaited: internalValue.timeframe.value?.isAwaited ?? null,

        maxOccurrencesPlayer: internalValue.occurrences.perPlayer,
        maxOccurrencesGlobal: internalValue.occurrences.perJourney
    };

    updatedGame.gameNotifications = applyMessage(internalValue.messages.start, updatedGame, NotificationType.RoundStarted, filter);
    updatedGame.gameNotifications = applyMessage(internalValue.messages.progress, updatedGame, NotificationType.RoundProgress, filter);

    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.start, updatedGame, IncentiveStage.RoundStarted, filter);
    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.progress, updatedGame, IncentiveStage.RoundProgress, filter);

    return { game: updatedGame, participantId };
};


export const applyToExternalValue_RoundEndNode = (internalValue, externalValue) => {
    const { game, participantId } = externalValue;

    const filter = { participantId: participantId, unionRuleId: null };

    const updatedGame = {
        ...game,
    };

    updatedGame.gameNotifications = applyMessage(internalValue.messages.roundSuccess, updatedGame, NotificationType.RoundSucceeded, filter);
    updatedGame.gameNotifications = applyMessage(internalValue.messages.roundFailure, updatedGame, NotificationType.RoundFailed, filter);
    // updatedGame.gameNotifications = applyMessage(internalValue.messages.roundCancel, updatedGame, NotificationType.RoundCancelled, filter);

    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.roundSuccess, updatedGame, IncentiveStage.RoundSucceeded, filter);
    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.roundFailure, updatedGame, IncentiveStage.RoundFailed, filter);
    // updatedGame.rewardUnions = applyIncentives(internalValue.incentives.roundCancel, updatedGame, IncentiveStage.RoundCancelled, filter);

    return { game: updatedGame, participantId };
};


export const applyToExternalValue_MilestoneNode = (internalValue, externalValue) => {
    const { game, participantId } = externalValue;  // TODO: rule?

    const filter = { participantId: participantId, unionRuleId: internalValue.unionRuleId };

    const updatedGame = {
        ...game,
    };

    // TODO: unionRuleId?

    const updatedRule = findRule(updatedGame, internalValue.unionRuleId);

    updatedRule.id = internalValue.target.id;
    updatedRule.name = internalValue.target.name;

    if (internalValue.timeframe.type === "none") {
        updatedRule.periodValue = null;
        updatedRule.periodUnit = null;
    }
    else if (internalValue.timeframe.type === "duration") {
        updatedRule.periodValue = internalValue.timeframe.value.amount;
        updatedRule.periodUnit = internalValue.timeframe.value.unit;
    }

    updatedGame.gameNotifications = applyMessage(internalValue.messages.success, updatedGame, NotificationType.MilestoneSucceeded, filter);
    updatedGame.gameNotifications = applyMessage(internalValue.messages.milestoneProgress, updatedGame, NotificationType.MilestoneProgress, filter);
    updatedGame.gameNotifications = applyMessage(internalValue.messages.failure, updatedGame, NotificationType.MilestoneFailed, filter);
    // updatedGame.gameNotifications = applyMessage(internalValue.messages.cancel, updatedGame, NotificationType.MilestoneCancelled, filter);

    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.success, updatedGame, IncentiveStage.MilestoneSucceeded, filter);
    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.milestoneProgress, updatedGame, IncentiveStage.MilestoneProgress, filter);
    updatedGame.rewardUnions = applyIncentives(internalValue.incentives.failure, updatedGame, IncentiveStage.MilestoneFailed, filter);
    // updatedGame.rewardUnions = applyIncentives(internalValue.incentives.cancel, updatedGame, IncentiveStage.MilestoneCancelled, filter);

    return { game: updatedGame, participantId, rule: updatedRule };
};


const getTimeframe = (game) => {
    if ((game.periodValue === null || game.periodValue === undefined) && (game.periodUnit === null || game.periodUnit === undefined) && (game.periodAligned === null || game.periodAligned === undefined) && (game.periodAwaited === null || game.periodAwaited === undefined)) {
        return {
            type: "none",
            value: undefined
        };
    }

    if ((game.periodValue !== null && game.periodValue !== undefined) && (game.periodUnit !== null && game.periodUnit !== undefined) && (game.periodAligned !== null && game.periodAligned !== undefined) && (game.periodAwaited !== null && game.periodAwaited !== undefined)) {
        return {
            type: "duration",
            value: {
                amount: game.periodValue,
                unit: game.periodUnit,
                isAligned: game.periodAligned,
                isAwaited: game.periodAwaited
            }
        };
    }

    throw new Error(`Invalid timeframe properties (amount=${amount}, unit=${unit}, isAligned=${isAligned}, isAwaited=${isAwaited})`);
};


const getMessage = (game, type, filter = {}) => {
    const { participantId = null, unionRuleId = null } = filter;

    const matchingGameNotifications = game.gameNotifications.filter(n => (n.type === type) && (n.participantId === participantId) && (n.unionRuleId === unionRuleId));
    if (matchingGameNotifications.length === 0) return null;

    const notification = matchingGameNotifications[0];

    return {
        id: notification.id,
        type: notification.type,
        participantId: notification.participantId,
        unionRuleId: notification.unionRuleId,
        title: notification.title,
        body: notification.description,
        hourFrom: notification.hourFrom,
        hourTo: notification.hourTo,
        conditions: notification.conditions
    }
};


const getIncentives = (game, stage, filter = {}) => {
    const { participantId = null, unionRuleId = null } = filter;

    const matchingRewardUnions = game.rewardUnions.filter(u => {
        if ((stage === null) || (stage === IncentiveStage.RoundSucceeded)) {
            // TODO: remove this when Stage becomes non-nullable in backend
            if ((u.stage !== null) && (u.stage !== IncentiveStage.RoundSucceeded)) return false;
        } else {
            if (u.stage !== stage) return false;
        }

        if (u.participantId !== participantId) return false;
        if (u.unionRuleId !== unionRuleId) return false;

        return true;
    });

    const incentives = [];

    matchingRewardUnions.forEach(rewardUnion => {
        if (rewardUnion.currency) {
            incentives.push({
                id: 'currency',
                name: 'Currency',
                stage: rewardUnion.stage,
                participantId: rewardUnion.participantId,
                unionRuleId: rewardUnion.unionRuleId,
                rankFrom: rewardUnion.rankFrom,
                rankTo: rewardUnion.rankTo,
                amount: Math.abs(rewardUnion.currency),
                negative: (rewardUnion.currency < 0)
            });
        }

        if (rewardUnion.experience) {
            incentives.push({
                id: 'experience',
                name: 'Experience',
                stage: rewardUnion.stage,
                participantId: rewardUnion.participantId,
                unionRuleId: rewardUnion.unionRuleId,
                rankFrom: rewardUnion.rankFrom,
                rankTo: rewardUnion.rankTo,
                amount: Math.abs(rewardUnion.experience),
                negative: (rewardUnion.experience < 0)
            });
        }

        rewardUnion.rewards.forEach(reward => {
            incentives.push({
                id: reward.id,
                name: reward.name,
                stage: rewardUnion.stage,
                participantId: rewardUnion.participantId,
                unionRuleId: rewardUnion.unionRuleId,
                rankFrom: rewardUnion.rankFrom,
                rankTo: rewardUnion.rankTo,
                negative: reward.negative
            });
        });
    });

    return incentives;
};


const getProgressConditions = (game, stage, unionRuleId = null) => {
    const matchingRewardUnions = game.rewardUnions.filter(u => (u.stage === stage) && (u.unionRuleId === unionRuleId));

    const conditions = [];

    matchingRewardUnions.forEach(rewardUnion => {
        conditions.push(...rewardUnion.conditions);
    });

    return conditions;
};


const applyMessage = (message, game, type, filter = {}) => {
    const { participantId = null, unionRuleId = null } = filter;

    const updatedGameNotifications = game.gameNotifications.filter(n => !((n.type === type) && (n.participantId === participantId) && (n.unionRuleId === unionRuleId)));

    if (message) {
        updatedGameNotifications.push({
            id: newId(),
            type: type,
            participantId: participantId,
            unionRuleId: unionRuleId,
            name: message.title,
            title: message.title,
            description: message.body,
            hourFrom: message.hourFrom,
            hourTo: message.hourTo,
            conditions: message.conditions,
            maxOccurrences: 1,  // TODO: ???
            period: null  // TODO: ???
        });
    }

    return updatedGameNotifications;
};


const applyIncentives = (incentives, game, stage, filter = {}) => {
    const { participantId = null, unionRuleId = null } = filter;

    const updatedRewardUnions = game.rewardUnions.filter(u => {
        if ((stage === null) || (stage === IncentiveStage.RoundSucceeded)) {
            // TODO: remove this when Stage becomes non-nullable in backend
            if ((u.stage !== null) && (u.stage !== IncentiveStage.RoundSucceeded)) return true;
        } else {
            if (u.stage !== stage) return true;
        }

        if (u.participantId !== participantId) return true;
        if (u.unionRuleId !== unionRuleId) return true;

        return false;
    });

    const newRewardUnions = [];

    incentives.forEach(incentive => {
        let rewardUnion = newRewardUnions.find(ru => (ru.rankFrom === incentive.rankFrom) && (ru.rankTo === incentive.rankTo));

        if (!rewardUnion) {
            rewardUnion = {
                id: newId(),
                stage: stage,
                participantId: participantId,
                unionRuleId: unionRuleId,
                rankFrom: incentive.rankFrom,
                rankTo: incentive.rankTo,
                currency: 0,
                experience: 0,
                rewards: [],
                conditions: []  // TODO: ???
            };

            newRewardUnions.push(rewardUnion);
        }

        if (incentive.id === 'currency') {
            rewardUnion.currency += incentive.negative ? -incentive.amount : incentive.amount;
        }
        else if (incentive.id === 'experience') {
            rewardUnion.experience += incentive.negative ? -incentive.amount : incentive.amount;
        }
        else {
            rewardUnion.rewards.push({
                id: incentive.id,
                name: incentive.name,
                negative: incentive.negative
            });
        }
    });

    newRewardUnions.forEach(rewardUnion => {
        if (rewardUnion.currency === 0) {
            rewardUnion.currency = null;
        }

        if (rewardUnion.experience === 0) {
            rewardUnion.experience = null;
        }

        if (rewardUnion.currency || rewardUnion.experience || rewardUnion.rewards.length > 0) {
            updatedRewardUnions.push(rewardUnion);
        }
    });

    return updatedRewardUnions;
};


export const removeOrphanMessages = (game) => {
    game.gameNotifications = game.gameNotifications.filter(n => (n.unionRuleId === null) || findRule(game, n.unionRuleId));
};


export const removeOrphanIncentives = (game) => {
    game.rewardUnions = game.rewardUnions.filter(u => (u.unionRuleId === null) || findRule(game, u.unionRuleId));
};


const periodToTimeframe = (period) => {
    switch (period) {
        case null: return { type: "none", value: null };

        case 100: return { type: "duration", value: { amount: 1, unit: "d", isAligned: true, isAwaited: true } };
        case 101: return { type: "duration", value: { amount: 1, unit: "d", isAligned: false, isAwaited: false } };
        case 102: return { type: "duration", value: { amount: 2, unit: "d", isAligned: false, isAwaited: false } };
        case 103: return { type: "duration", value: { amount: 3, unit: "d", isAligned: false, isAwaited: false } };
        case 104: return { type: "duration", value: { amount: 4, unit: "d", isAligned: false, isAwaited: false } };
        case 105: return { type: "duration", value: { amount: 5, unit: "d", isAligned: false, isAwaited: false } };
        case 106: return { type: "duration", value: { amount: 6, unit: "d", isAligned: false, isAwaited: false } };
        case 107: return { type: "duration", value: { amount: 7, unit: "d", isAligned: false, isAwaited: false } };
        case 108: return { type: "duration", value: { amount: 8, unit: "d", isAligned: false, isAwaited: false } };
        case 109: return { type: "duration", value: { amount: 9, unit: "d", isAligned: false, isAwaited: false } };
        case 110: return { type: "duration", value: { amount: 10, unit: "d", isAligned: false, isAwaited: false } };
        case 114: return { type: "duration", value: { amount: 14, unit: "d", isAligned: false, isAwaited: false } };
        case 120: return { type: "duration", value: { amount: 20, unit: "d", isAligned: false, isAwaited: false } };
        case 130: return { type: "duration", value: { amount: 30, unit: "d", isAligned: false, isAwaited: false } };

        case 200: return { type: "duration", value: { amount: 1, unit: "w", isAligned: true, isAwaited: true } };
        case 201: return { type: "duration", value: { amount: 1, unit: "w", isAligned: false, isAwaited: false } };
        case 202: return { type: "duration", value: { amount: 2, unit: "w", isAligned: false, isAwaited: false } };
        case 203: return { type: "duration", value: { amount: 3, unit: "w", isAligned: false, isAwaited: false } };
        case 204: return { type: "duration", value: { amount: 4, unit: "w", isAligned: false, isAwaited: false } };
        case 205: return { type: "duration", value: { amount: 5, unit: "w", isAligned: false, isAwaited: false } };
        case 206: return { type: "duration", value: { amount: 6, unit: "w", isAligned: false, isAwaited: false } };

        case 300: return { type: "duration", value: { amount: 1, unit: "m", isAligned: true, isAwaited: true } };
        case 301: return { type: "duration", value: { amount: 1, unit: "m", isAligned: false, isAwaited: false } };
        case 302: return { type: "duration", value: { amount: 2, unit: "m", isAligned: false, isAwaited: false } };
        case 303: return { type: "duration", value: { amount: 3, unit: "m", isAligned: false, isAwaited: false } };
        case 306: return { type: "duration", value: { amount: 6, unit: "m", isAligned: false, isAwaited: false } };

        case 400: return { type: "duration", value: { amount: 1, unit: "y", isAligned: true, isAwaited: true } };
        case 401: return { type: "duration", value: { amount: 1, unit: "y", isAligned: false, isAwaited: false } };

        // Unknown value
        default: return { type: "none", value: null };
    }
};


const timeframeToPeriod = (timeframe) => {
    if (timeframe.type === "none") {
        return null;
    }

    if (timeframe.type === "duration") {
        const { amount, unit, isAligned, isAwaited } = timeframe.value;

        switch (true) {
            case (amount === 1) && (unit === "d") && isAligned && isAwaited: return 100;
            case (amount === 1) && (unit === "d") && !isAligned && !isAwaited: return 101;
            case (amount === 2) && (unit === "d") && !isAligned && !isAwaited: return 102;
            case (amount === 3) && (unit === "d") && !isAligned && !isAwaited: return 103;
            case (amount === 4) && (unit === "d") && !isAligned && !isAwaited: return 104;
            case (amount === 5) && (unit === "d") && !isAligned && !isAwaited: return 105;
            case (amount === 6) && (unit === "d") && !isAligned && !isAwaited: return 106;
            case (amount === 7) && (unit === "d") && !isAligned && !isAwaited: return 107;
            case (amount === 8) && (unit === "d") && !isAligned && !isAwaited: return 108;
            case (amount === 9) && (unit === "d") && !isAligned && !isAwaited: return 109;
            case (amount === 10) && (unit === "d") && !isAligned && !isAwaited: return 110;
            case (amount === 14) && (unit === "d") && !isAligned && !isAwaited: return 114;
            case (amount === 20) && (unit === "d") && !isAligned && !isAwaited: return 120;
            case (amount === 30) && (unit === "d") && !isAligned && !isAwaited: return 130;

            case (amount === 1) && (unit === "w") && isAligned && isAwaited: return 200;
            case (amount === 1) && (unit === "w") && !isAligned && !isAwaited: return 201;
            case (amount === 2) && (unit === "w") && !isAligned && !isAwaited: return 202;
            case (amount === 3) && (unit === "w") && !isAligned && !isAwaited: return 203;
            case (amount === 4) && (unit === "w") && !isAligned && !isAwaited: return 204;
            case (amount === 5) && (unit === "w") && !isAligned && !isAwaited: return 205;
            case (amount === 6) && (unit === "w") && !isAligned && !isAwaited: return 206;

            case (amount === 1) && (unit === "m") && isAligned && isAwaited: return 300;
            case (amount === 1) && (unit === "m") && !isAligned && !isAwaited: return 301;
            case (amount === 2) && (unit === "m") && !isAligned && !isAwaited: return 302;
            case (amount === 3) && (unit === "m") && !isAligned && !isAwaited: return 303;
            case (amount === 6) && (unit === "m") && !isAligned && !isAwaited: return 306;

            case (amount === 1) && (unit === "y") && isAligned && isAwaited: return 400;
            case (amount === 1) && (unit === "y") && !isAligned && !isAwaited: return 401;

            // Unknown value
            default: return -1;
        }
    }
};
