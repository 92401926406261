import React, { useEffect, useLayoutEffect } from 'react';
import { ReactFlow, ReactFlowProvider, useNodesState, useEdgesState, getNodesBounds } from 'reactflow';
import dagre from 'dagre';

const NODE_SPACING = 100;

const AutoLayout = ({ nodes, edges, offset }) => {
    /*
    const rf = useReactFlow();

    const nodes = rf.getNodes();
    const edges = rf.getEdges();

    const setNodes = useStore((state) => state.setNodes);
    const setEdges = useStore((state) => state.setEdges);
    */

    const layout = (allNodes, allEdges) => {
        const affectedNodes = allNodes.filter(node => !node.hidden);
        const affectedNodeIds = new Set(affectedNodes.map(node => node.id));

        const affectedEdges = allEdges.filter(edge => affectedNodeIds.has(edge.source) && affectedNodeIds.has(edge.target));

        const g = new dagre.graphlib.Graph();
        g.setDefaultEdgeLabel(() => ({}));

        g.setGraph({
            rankdir: 'TB',
            ranksep: NODE_SPACING,
            nodesep: NODE_SPACING,
        });

        affectedNodes.forEach((node) => {
            g.setNode(node.id, { width: node.width, height: node.height });
        });

        affectedEdges.forEach((edge) => {
            g.setEdge(edge.source, edge.target);
        });

        dagre.layout(g, {});

        affectedNodes.forEach((node) => {
            const nodeWithPosition = g.node(node.id);
            node.targetPosition = 'top';
            node.sourcePosition = 'bottom';

            // unfortunately we need this little hack to pass a slightly different position
            // to notify react flow about the change. More over we are shifting the dagre node position
            // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
            node.position = {
                x: nodeWithPosition.x - nodeWithPosition.width / 2 + Math.random() / 1000 + offset,
                y: nodeWithPosition.y - nodeWithPosition.height / 2 + Math.random() / 1000 + offset,
            };
        });
    };

    useLayoutEffect(() => {
        if (nodes && nodes.length && nodes.every(n => n.width && n.height))
        {
            layout(nodes, edges);

            /*
            onNodesChange([...layoutedNodes]);

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);

            console.log("Did useLayoutEffect");
            console.log(nodes);
            */
        }
    }, [nodes, edges]);

    return null;
};

const NodeTree = ({ nodeTypes, initialNodes, initialEdges, onChange, width, height, offset = 0, movable = false }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    useEffect(() => {
        if (onChange) {
            onChange(nodes, edges);
        }
    }, [nodes, edges]);

    // TODO: review this useEffect
    useEffect(() => {
        setNodes(initialNodes);
        setEdges(initialEdges);
    }, [initialNodes, initialEdges]);

    if (!width || !height) {
        const { x, y, width: calculatedWidth, height: calculatedHeight } = getNodesBounds(nodes);
        width = calculatedWidth + 2*offset;
        height = calculatedHeight + 2*offset;
    }

    return (
        <div style={{ width: width, height: height }}>
            <ReactFlowProvider>
                <ReactFlow
                    nodeTypes={nodeTypes}
                    nodes={nodes} edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodesDraggable={false} nodesConnectable={false}
                    nodesFocusable={true} edgesFocusable={true}
                    elementsSelectable={true}
                    panOnDrag={movable} panOnScroll={movable}
                    zoomOnScroll={false} zoomOnPinch={false} zoomOnDoubleClick={false}
                    deleteKeyCode={null}
                    proOptions={{ hideAttribution: true }}>
                    <AutoLayout nodes={nodes} edges={edges} offset={offset} />
                </ReactFlow>
            </ReactFlowProvider>
        </div>
    );
};

export default NodeTree;
