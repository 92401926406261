import './reset.css';
import 'reactflow/dist/style.css';

import React, { useRef, useMemo, useState, useEffect } from 'react';

import JourneyContext from './JourneyContext';

import JourneyStartNode from './nodes/JourneyStartNode';
import JourneyEndNode from './nodes/JourneyEndNode';
import RoundStartNode from './nodes/RoundStartNode';
import RoundEndNode from './nodes/RoundEndNode';
import NodeTree from './NodeTree';
import JourneyNode from './nodes/JourneyNode';
import JourneyControls from './JourneyControls';
import JourneyProfiles from './JourneyProfiles';

import { filterGameByProfile } from './convert';
import { InternalTreeBuilder } from './InternalTreeBuilder';
import { ExternalTreeBuilder } from './ExternalTreeBuilder';

const nodeTypes = {
    journeyStart: JourneyStartNode,
    journeyEnd: JourneyEndNode,
    roundStart: RoundStartNode,
    roundEnd: RoundEndNode,
    journey: JourneyNode
};

const JourneyEditor = ({ initialGame, onChange, isGameMode, isLeaderboardMode, allRules, allRankings, allRewards, allParticipants }) => {
    const editorRef = useRef(null);

    const [game, setGame] = useState(initialGame);
    const [activeProfile, setActiveProfile] = useState(null);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const activeProfileGame = useMemo(() => {
        return filterGameByProfile(game, activeProfile ? activeProfile.id : null);
    }, [game, activeProfile]);

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(document.fullscreenElement !== null);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const addProfileToGame = (game, profile, sourceProfile) => {
        const externalTreeBuilder = new ExternalTreeBuilder(game);

        externalTreeBuilder.addParticipant(profile, sourceProfile);

        return externalTreeBuilder.game;
    };

    const removeProfileFromGame = (game, profile) => {
        const externalTreeBuilder = new ExternalTreeBuilder(game);

        externalTreeBuilder.removeParticipant(profile);

        return externalTreeBuilder.game;
    };

    const [initialNodes, initialEdges] = useMemo(() => {
        const internalTreeBuilder = new InternalTreeBuilder(game);
        internalTreeBuilder.selectParticipant(activeProfile ? activeProfile.id : null);

        const [nodes, edges] = internalTreeBuilder.buildRootNodes();

        return [nodes, edges];
    }, [activeProfile]);

    const updateGame = (game) => {
        setGame(game);
        onChange(game);
    };

    /**
     * Calls the callback provided by a node to update the external value (game) with the changes in that node.
     */
    const onNodeValueUpdated = (updateExternalValueCallback) => {
        const externalValue = {
            game: game,
            participantId: activeProfile ? activeProfile.id : null
        };

        const updatedExternalValue = updateExternalValueCallback(externalValue);

        updateGame(updatedExternalValue.game);
    };

    const onProfileSelect = (profile) => {
        // TODO: profile.isActive (?)
        game.gameParticipants.forEach(p => p.participant.isActive = false);
        if (profile !== null) {
            profile.isActive = true;
        }
        setActiveProfile(profile);
    };

    const onProfileAdd = (profile, sourceProfile) => {
        game.gameParticipants.forEach(p => p.participant.isActive = false);
        profile.isActive = true;
        updateGame(addProfileToGame(game, profile, sourceProfile));
    };

    const onProfileRemove = (profile) => {
        updateGame(removeProfileFromGame(game, profile));
    };

    const onToggleFullscreen = (value) => {
        if (!document.fullscreenElement && value) {
            editorRef.current.requestFullscreen();
        } else if (document.fullscreenElement && !value) {
            document.exitFullscreen();
        }
    };

    const backgroundColor = useMemo(() => isFullscreen ? "#666666" : null, [isFullscreen]);

    return (
        <div ref={editorRef} style={{ backgroundColor: backgroundColor }}>
            <JourneyProfiles initialProfiles={game.gameParticipants.map(o => o.participant)} allProfiles={allParticipants} onSelect={onProfileSelect} onAdd={onProfileAdd} onRemove={onProfileRemove} />

            <JourneyControls
                isFullscreen={isFullscreen} onToggleFullscreen={onToggleFullscreen}
            />

            <JourneyContext.Provider value={{ game, activeProfile, activeProfileGame, onNodeValueUpdated, allRules, allRankings, allRewards, allParticipants, isGameMode, isLeaderboardMode }}>
                <NodeTree nodeTypes={nodeTypes} initialNodes={initialNodes} initialEdges={initialEdges} width="100%" height="100vh" movable={true} />
            </JourneyContext.Provider>
        </div>
    );
};

export default JourneyEditor;
