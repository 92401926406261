import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useReactFlow } from 'reactflow';
import {
    Box
} from "@mui/material";

import JourneyContext from '../JourneyContext';

import Node from "../Node";
import NodeEditMode from "../NodeEditMode";
import NodeViewMode from "../NodeViewMode";
import { theme } from "../theme"
import JourneyStartNodeView from './JourneyStartNodeView';
import JourneyStartNodeEdit from './JourneyStartNodeEdit';
import { applyToExternalValue_JourneyStartNode } from '../convert';

const JourneyStartNode = (props) => {
    const {
        id,
        data: {
            externalValue,
            internalValue,
            nodeProps
        }
    } = props;

    const rf = useReactFlow();

    const { onNodeValueUpdated } = useContext(JourneyContext);

    let outcomeTypes = useMemo(() => {
        return ["journeyStart"];
    }, []);

    const updateValue = (updatedInternalValue) => {
        onNodeValueUpdated((currentExternalValue) => {
            const updatedExternalValue = applyToExternalValue_JourneyStartNode(updatedInternalValue, currentExternalValue);

            rf.setNodes((nodes) => nodes.map((node) => {
                if (node.id !== id) return node;

                return {
                    ...node,
                    data: {
                        ...node.data,
                        internalValue: updatedInternalValue,
                        externalValue: updatedExternalValue
                    }
                };
            }));

            return updatedExternalValue;
        });
    };

    return (
        <Node title="Journey Start" isRemoveable={false} isConnectable={false} {...nodeProps}>
            <NodeEditMode>
                <Box sx={{ mx: 2, my: 6 }}>
                    <JourneyStartNodeEdit value={internalValue} onChange={updateValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeEditMode>
            <NodeViewMode>
                <Box sx={{ mx: 2, my: 3 }}>
                    <JourneyStartNodeView value={internalValue} outcomeTypes={outcomeTypes} />
                </Box>
            </NodeViewMode>
        </Node>
    );
};

export default JourneyStartNode;
