import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import OutcomeEditor from '../controls/OutcomeEditor';
import JourneyContext from '../JourneyContext';

const JourneyEndNodeEdit = ({ value, onChange, outcomeTypes }) => {
    const { message, incentives } = value;

    const setMessage = (message) => {
        onChange({ ...value, message });
    };

    const setIncentives = (incentives) => {
        onChange({ ...value, incentives });
    };

    return (
        <Stack useFlexGap>
            <OutcomeEditor types={outcomeTypes}
                           messages={{ journeyEnd: message }} onMessageChange={(messages) => setMessage(messages.journeyEnd)}
                           incentives={{ journeyEnd: incentives }} onIncentiveChange={(incentives) => setIncentives(incentives.journeyEnd)} />
        </Stack>
    );
};

export default JourneyEndNodeEdit;
