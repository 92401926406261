import React, { useContext, useMemo, useState } from 'react';
import { Box, Chip, Divider, Stack, Typography } from '@mui/material';

import JourneyContext from '../JourneyContext';

import Node from '../Node';
import NodeTree from '../NodeTree';
import AddButtonGroup from '../controls/AddButtonGroup';

import MilestoneNode from './MilestoneNode';
// import JourneyNode from './JourneyNode';

import { theme } from '../theme';
import { ExternalTreeBuilder } from '../ExternalTreeBuilder';
import { InternalTreeBuilder } from '../InternalTreeBuilder';

const createSubgroups = (game, participantId, rules, ruleUnions) => {
    const internalTreeBuilder = new InternalTreeBuilder(game);
    internalTreeBuilder.selectParticipant(participantId);

    return internalTreeBuilder.buildGroupNode(rules, ruleUnions);
};

const getConjunctionName = (type) => {
    switch (type) {
        case 1: return 'AND';
        case 2: return 'OR';
        case 3: return 'THEN';
    }
};

const getConjunctionType = (name) => {
    switch (name) {
        case 'AND': return 1;
        case 'OR': return 2;
        case 'THEN': return 3;
    }
};


const MilestoneGroupNode = (props) => {
    const {
        id,
        data: {
            reason,
            externalValue: { game, participantId, baseUnion, rules, ruleUnions, childrenConjunction },
            nodeProps
        }
    } = props;

    const { onNodeValueUpdated } = useContext(JourneyContext);

    const [mode, setMode] = useState("view");

    const [_childrenConjunction, setChildrenConjunction] = useState(childrenConjunction);
    const [_subgroups, setSubgroups] = useState(createSubgroups(game, participantId, rules, ruleUnions));

    const operator = getConjunctionName(_childrenConjunction);

    const onEditMode = () => {
        setMode("edit");
    }

    const onViewMode = () => {
        setMode("view");
    };

    const addSubgroupWithMilestone = () => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            const nodeBuilderParams = externalTreeBuilder.parallelRuleUnion_appendRule(baseUnion);

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneNode = internalTreeBuilder.create_MilestoneNode(nodeBuilderParams);

            setSubgroups([
                ..._subgroups,
                {
                    nodes: [milestoneNode],
                    edges: []
                }
            ]);

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    const addSubgroupWithGroup = () => {
        onNodeValueUpdated((currentExternalValue) => {
            // Building external tree

            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            const nodeBuilderParams = externalTreeBuilder.parallelRuleUnion_appendParallelRuleUnion(baseUnion);

            // Building internal tree

            const internalTreeBuilder = new InternalTreeBuilder(externalTreeBuilder.game);
            internalTreeBuilder.selectParticipant(externalTreeBuilder.participantId);

            const milestoneGroupNode = internalTreeBuilder.create_MilestoneGroupNode(nodeBuilderParams);

            setSubgroups([
                ..._subgroups,
                {
                    nodes: [milestoneGroupNode],
                    edges: []
                }
            ]);

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    const handleSubgroupChange = (subgroup, nodes, edges) => {
        if (nodes.length === 0) {
            setSubgroups(_subgroups.filter(g => g !== subgroup));
        }
    };

    const changeOperator = (newOperator) => {
        onNodeValueUpdated((currentExternalValue) => {
            const externalTreeBuilder = new ExternalTreeBuilder(currentExternalValue.game);
            externalTreeBuilder.selectParticipant(currentExternalValue.participantId);

            const newChildrenConjunction = getConjunctionType(newOperator);
            const nodeBuilderParams = externalTreeBuilder.ruleUnion_setChildrenConjunction(baseUnion, newChildrenConjunction);

            setChildrenConjunction(newChildrenConjunction);

            const updatedExternalValue = {
                game: externalTreeBuilder.game,
                participantId: externalTreeBuilder.participantId,
                ...nodeBuilderParams
            };

            return updatedExternalValue;
        });
    };

    // const addSubgroupWithJourney = () => {
    //     const value = {};
    //     addSubgroup("journey", value);
    // };

    const nodeTypes = useMemo(() => ({
        milestone: MilestoneNode,
        milestoneGroup: MilestoneGroupNode,
        // journey: JourneyNode
    }), []);

    return (
        <Node isContainer reason={reason} onEditMode={onEditMode} onViewMode={onViewMode} title={
            <Stack direction="row" spacing={1}>
                <Typography sx={{ textTransform: "uppercase", fontWeight: "bold", px: 1 }}>Group</Typography>
                {(mode === "edit") && (
                    <>
                        <Chip variant={operator === "OR" ? "filled" : "outlined"} size="small" label="OR" clickable onClick={() => changeOperator("OR")} />
                        <Chip variant={operator === "AND" ? "filled" : "outlined"} size="small" label="AND" clickable onClick={() => changeOperator("AND")} />
                    </>
                )}
            </Stack>
        } {...nodeProps}>
            <Stack direction="row" justifyContent="center" useFlexGap sx={{ mx: 2, my: 2 }}>
                {
                    _subgroups.map((g, index) => (
                        <React.Fragment key={index}>
                            {(index > 0) && (
                                <Divider orientation="vertical" flexItem sx={{ "&::before, &::after": { borderColor: "white" } }}>
                                    <Chip label={operator} sx={{ backgroundColor: "white" }} />
                                </Divider>
                            )}
                            <NodeTree
                                nodeTypes={nodeTypes}
                                initialNodes={g.nodes} initialEdges={g.edges}
                                onChange={(nodes, edges) => handleSubgroupChange(g, nodes, edges)}
                                offset={50} movable={false}
                            />
                        </React.Fragment>
                    ))
                }
                {(mode === "edit") && (
                    <>
                        {(_subgroups.length > 0) && (
                            <Divider orientation="vertical" flexItem sx={{ "&::before, &::after": { borderColor: "white" } }}>
                                <Chip label={operator} sx={{ backgroundColor: "white" }} />
                            </Divider>
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: 250, minHeight: 250 }}>
                            <AddButtonGroup
                                onAddMilestone={addSubgroupWithMilestone}
                                onAddGroup={addSubgroupWithGroup}
                                // onAddJourney={addSubgroupWithJourney}
                            />
                        </Box>
                    </>
                )}
            </Stack>
        </Node>
    );
};

export default MilestoneGroupNode;
